import React, { useEffect } from 'react';
import { Grid, Stack, TextField, Typography, FormControl, Select, MenuItem, Button, Divider } from '@mui/material';
import { CreateActividadParroquialEnum, ValuesEnum } from '../../../enums/pages/CreateActividadParroquialEnum';
import { Sizes, State } from '../../../enums/components/componentsEnum';
import DocumentTypeComponent from '../../../components/autocomplete/documentType/DocumentTypeComponent';
import { getDocumentTypes } from '../../../utils/forms/formsUtils';

const FormCoordinator = ({ actividad, setActividad, handleDocumentTypeChange, handleFetchPerson, handleItemChange, liturgias, stylesActividadParroquial, isFetchingPerson = false }: any) => {

  const addCoordinator = () => {
    const newCoordinator = {
      name: '',
      surname: '',
      documentType: '',
      document: '',
      birthdate: '',
      email: '',
      phone: '',
      task: '',
      responseToRiteId: '',
    };
    setActividad({
      ...actividad,
      coordinators: [...actividad.coordinators, newCoordinator]
    });
  };

  const removeCoordinator = (index: number) => {
    const updatedCoordinators = actividad.coordinators.filter((_: any, idx: number) => idx !== index);
    setActividad({ ...actividad, coordinators: updatedCoordinators });
  };

  const updateCoordinator = (index: number, field: string, value: any) => {
    console.log(index, field, value);
    
    const updatedCoordinators = actividad.coordinators.map((coordinator: any, idx: number) =>
      idx === index ? { ...coordinator, [field]: value } : coordinator
    );
    setActividad({ ...actividad, coordinators: updatedCoordinators });
  };

  return (
    <Grid sx={stylesActividadParroquial.gridFormControl}>
      {actividad.coordinators?.map((coordinator: any, index: number) => (
        <>
          <Typography style={stylesActividadParroquial.typography}>
            Coordinador {index + 1}
          </Typography>
          <Stack direction="row" spacing={2} key={index}>
            <TextField
              label={CreateActividadParroquialEnum.FIRST_NAME}
              value={coordinator.name}
              onChange={(e) => updateCoordinator(index, 'name', e.target.value)}
              fullWidth
              size={Sizes.SMALL}
              style={stylesActividadParroquial.texfields}
              required
              disabled={isFetchingPerson[index]}
              />
            <TextField
              label={CreateActividadParroquialEnum.LAST_NAME}
              value={coordinator.surname}
              onChange={(e) => updateCoordinator(index, 'surname', e.target.value)}
              fullWidth
              size={Sizes.SMALL}
              style={stylesActividadParroquial.texfields}
              required
              disabled={isFetchingPerson[index]}
              />
          </Stack>

          <Stack direction="row" spacing={2}>
            <Grid container style={stylesActividadParroquial.gridCoordinator}>
              <Grid item xs={3}>
                <DocumentTypeComponent
                  label={CreateActividadParroquialEnum.TIPO_DE_DOCUMENTO}
                  countries={getDocumentTypes()}
                  value={coordinator.documentType}
                  onChange={(id: any, value: any) => updateCoordinator(index, 'documentType', value.id)}
                  size={Sizes.SMALL}
                  disabled={isFetchingPerson[index]}
                  style={{margin: 0, padding: 0}}
                  />
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  label={CreateActividadParroquialEnum.NUMERO_DE_DOCUMENTO}
                  value={coordinator.document}
                  onChange={(e) => updateCoordinator(index, 'document', e.target.value)}
                  onBlur={() => handleFetchPerson(coordinator.document, coordinator.documentType, index)}
                  fullWidth
                  size={Sizes.SMALL}
                  style={stylesActividadParroquial.texfields}
                  required
                  disabled={isFetchingPerson[index]}
                  />
              </Grid>
            </Grid>
            <TextField
              label={CreateActividadParroquialEnum.FECHA_DE_NACIMIENTO}
              type="date"
              autoComplete={State.OFF}
              sx={{ marginTop: '360px' }}
              value={coordinator.birthdate}
              onChange={(e) => updateCoordinator(index, 'birthdate', e.target.value)}
              fullWidth
              size={Sizes.SMALL}
              InputLabelProps={{
                shrink: true,
              }}
              required
              disabled={isFetchingPerson[index]}
              />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label={CreateActividadParroquialEnum.MAIL_DE_CONTACTO}
              value={coordinator.email}
              onChange={(e) => updateCoordinator(index, 'email', e.target.value)}
              fullWidth
              size={Sizes.SMALL}
              required
              disabled={isFetchingPerson[index]}
              />
            <TextField
              label={CreateActividadParroquialEnum.CELULAR_DE_CONTACTO}
              value={coordinator.phone}
              onChange={(e) => updateCoordinator(index, 'phone', e.target.value)}
              fullWidth
              size={Sizes.SMALL}
              required
              disabled={isFetchingPerson[index]}
              />
          </Stack>
          <Divider style={{ marginTop: 10 }} />
          
          {index !== 0 && (
            <>
            <Button
            variant="contained"
            color="error"
            onClick={() => removeCoordinator(index)}
            style={{ marginTop: 10 }}
            >
            {CreateActividadParroquialEnum.REMOVE_COORDINATOR}
            </Button>
            <Divider style={{ marginTop: 10 }} />
            </>
          )}
              </>

      ))}
      
      <Button variant="contained" onClick={addCoordinator}>
        {CreateActividadParroquialEnum.ADD_COORDINATOR}
      </Button>

          <Stack direction="row">
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Typography style={stylesActividadParroquial.typographyRolAndRito}>
                  {CreateActividadParroquialEnum.ROL_DEL_COORDINADOR}
                </Typography>
                <TextField
                  label={CreateActividadParroquialEnum.TAREA}
                  value={actividad.task}
              onChange={(e) => handleItemChange(e, 'task')}
                  fullWidth
                  size={Sizes.SMALL}
                  style={stylesActividadParroquial.texfields}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={stylesActividadParroquial.typographyRolAndRito}>
                  {CreateActividadParroquialEnum.RESPONDE_A_UNA_LITURGIA}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={actividad.liturgiaId}
                    onChange={(e) => handleItemChange(e, 'liturgiaId')}
                    fullWidth
                    size={Sizes.SMALL}
                    style={stylesActividadParroquial.selectTipo}
                  >
                    {liturgias?.map((rito: any) => (
                      <MenuItem key={rito.id} value={rito.id}>
                        {rito.tipoEventoLiturgico}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
    </Grid>
  );
};

export default FormCoordinator;