import { Avatar, Box, Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { stylesCreateTurno } from "./stylesCreateTurno";
import { CreateTurnoEnum, Periodicidad, ValuesEnum } from "../../../enums/pages/CreateTurnoEnum";
import { ScheduleAppointmentEnum, Sizes } from "../../../enums/components/componentsEnum";
import moment, { Moment } from "moment";
import React from "react";
import { addNotification } from "../../../utils/notifications";
import { queryClient } from "../../../App";
import { TurnoState, dias, handleDayProperties, handleDiaChange, handleEndTimeChange, handleSacerdoteChange, handleSectorChange, handleSelectDay, handleSemanaChange, handleStartTimeChange, handleTipoChange, initializeTurno, opcionesDiaNumericos, opcionesSemana, opcionesTipo } from "../../../utils/pages/TurnoUtils";
import iglesiasService from "../../../services/iglesias";
import turnoExpecificationService from "../../../services/turnosExpecification";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { convertHourToUTC, convertUTCToLocal } from "../../../utils/datesUtils/dateUtils";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import { CreateTurnoProps, LocationState } from "../../../interfaces/components/interfacesCreateTurno";
import { ActividadParroquialRoutes, ActividadParroquialTextos, CreateActividadParroquialEnum, ValuesLabelEnum } from "../../../enums/pages/CreateActividadParroquialEnum";
import NavLinkAdministratorGroups from "../../actividadesParroquiales/createActividadParroquial/LinksAdministrationGroup";
import { stylesActividadParroquial } from "../../actividadesParroquiales/createActividadParroquial/CreateActividadParroquialStyles";

const CreateTurno: React.FC<CreateTurnoProps> = ({ isActividadParroquial = false }) => {
    const navigate = useNavigate();
    const [turno, setTurno] = useState<TurnoState>({ ...initializeTurno() });
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const currentDate = moment();
    const location = useLocation();
    const turnoId = location.state?.turnoId;
    const [sectores, setSectores] = useState([]);
    const [sacerdotes, setSacerdotes] = useState([]);
    const [diasSeleccionados, setDiasSeleccionados] = useState<string[]>([]);

    useEffect(() => {
        iglesiasService
            .getSectores()
            .then((result) => {
                setSectores(result.sectores);
            })
            .catch((error) => {
                console.log(CreateTurnoEnum.ERROR_OBTENER_SECTORES, error);
            });

        const fetchSacerdotes = async () => {
            try {
                const response = await iglesiasService.getSacerdotes();
                setSacerdotes(response);
            } catch (error) {
                console.log(CreateTurnoEnum.ERROR_OBTENER_SACERDOTES, error);
            }
        };

        fetchSacerdotes();

    }, []);

    useEffect(() => {
        if (turnoId) {
            turnoExpecificationService.getExpecificationById(turnoId)
                .then((response: any) => {
                    setTurno({
                        ...turno,
                        type: response.type,
                        criteryFeriados: response.criteryFeriados,
                        periodicity: response.periodicity,
                        week: response.week,
                        day: response.day,
                        slots: response.slots,
                        sectorId: response.sector.id,
                        sacerdoteId: response.sacerdote.id,
                        startTime: response.startTime,
                        endTimeRepetition: response.endTimeRepetition,
                        startHour: convertUTCToLocal(response.startHour),
                        endHour: convertUTCToLocal(response.endHour),
                        enabledDays: response.enabledDays,
                    });
                    setSelectedDate(moment(response.date, 'YYYY-MM-DD'));
                    setDiasSeleccionados(response.enabledDays);
                })
                .catch((error: any) => {
                    console.log('Error al obtener los datos del turno', error);
                });
        }
    }, [turnoId]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        turno.startHour = convertHourToUTC(turno.startHour);
        turno.endHour = convertHourToUTC(turno.endHour);
        turno.startHourSpecial = turno.startHourSpecial ? convertHourToUTC(turno.startHourSpecial) : null;
        turno.endHourSpecial = turno.endHourSpecial ? convertHourToUTC(turno.endHourSpecial) : null;
        if (!turno.periodicity) {
            addNotification(
                queryClient,
                "error",
                CreateTurnoEnum.ERROR_PERIODICIDAD
            );
            return;
        }

        if (turno.criteryFeriados === '') {
            addNotification(
                queryClient,
                "error",
                CreateTurnoEnum.ERROR_CRITERIO_FERIADOS
            );
            return;
        } else if (turno.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL && (!turno.startHourSpecial || !turno.endHourSpecial)) {
            addNotification(
                queryClient,
                "error",
                CreateTurnoEnum.ERROR_HORARIO_ESPECIAL
            );
            return;
        }

        if (turno.periodicity === ValuesEnum.DIARIA && (!diasSeleccionados || diasSeleccionados.length === 0)) {
            addNotification(
                queryClient,
                "error",
                "Por favor, seleccione al menos un día de la semana."
            );
            return;
        } else if (turno.periodicity === ValuesEnum.MENSUAL && (!diasSeleccionados || diasSeleccionados.length === 0)) {
            addNotification(
                queryClient,
                "error",
                "Por favor, seleccione al menos un día del mes."
            );
            if (!turno.week) {
                addNotification(
                    queryClient,
                    "error",
                    "Por favor, seleccione una semana del mes."
                );
            }
            return;
        } else if (turno.periodicity === ValuesEnum.POR_FECHA && !turno.day) {
            addNotification(
                queryClient,
                "error",
                "Por favor, seleccione un día del mes."
            );
            return;
        }

        if (turnoId) {
            turnoExpecificationService.updateExpecificacion(turnoId, turno)
                .then((result: any) => {
                    addNotification(
                        queryClient,
                        "success",
                        CreateTurnoEnum.TURNO_ACTUALIZADO
                    );
                    navigate(-1);
                }).catch((error: any) => {
                    console.error(error);
                    addNotification(
                        queryClient,
                        "error",
                        error.message
                    );
                    setTurno({ ...turno, startHour: "", endHour: "" });
                });
        } else {
            turnoExpecificationService
                .create(
                    turno,
                    parseInt(turno.sectorId!)
                )
                .then((response: any) => {
                    addNotification(
                        queryClient,
                        "success",
                        CreateTurnoEnum.TURNO_SUCESS
                    );
                    navigate(-1);
                })
                .catch((error: any) => {
                    addNotification(
                        queryClient,
                        "error",
                        error.message
                    );
                    setTurno({ ...turno, startHour: "", endHour: "" });
                });
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleDateChange = (date: Moment | null) => {
        if (date) {
            setSelectedDate(date);
            setTurno((prevTurno: any) => {
                const startTimeMoment = prevTurno.startTime ? moment(prevTurno.startTime, 'YYYY-MM-DD') : null;
                const endTimeMoment = prevTurno.endTimeRepetition ? moment(prevTurno.endTimeRepetition, 'YYYY-MM-DD') : null;

                // If both dates are set, check if the selected date is one of them
                if (startTimeMoment && endTimeMoment) {
                    if (date.isSame(startTimeMoment, 'day')) {
                        return { ...prevTurno, startTime: null };
                    } else if (date.isSame(endTimeMoment, 'day')) {
                        return { ...prevTurno, endTimeRepetition: null };
                    } else {
                        return prevTurno;
                    }
                } else if (!startTimeMoment || !endTimeMoment) {
                    // If only one date is selected or none, allow selection
                    if (!startTimeMoment) {
                        let seteoStartTime = { ...prevTurno, startTime: date.format('YYYY-MM-DD') }
                        return seteoStartTime;
                    } else if (!endTimeMoment || date.isSame(startTimeMoment, 'day')) {
                        // Adjustment: Allow selecting endTime only if it's not the same as startTime
                        let seteoEndTime = { ...prevTurno, endTimeRepetition: date.format('YYYY-MM-DD') }
                        return seteoEndTime;
                    }
                }
            });
        } else {
            setSelectedDate(null);
        }
    };

    const isDateDisabled = useCallback((date: Moment) => {
        const startTimeMoment = turno.startTime ? moment(turno.startTime) : null;
        const endTimeMoment = turno.endTimeRepetition ? moment(turno.endTimeRepetition) : null;

        if ((startTimeMoment && date.isSame(startTimeMoment, 'day')) ||
            (endTimeMoment && date.isSame(endTimeMoment, 'day'))) {
            return false;
        }

        if (startTimeMoment && endTimeMoment) {
            return true;
        }

        return false;
    }, [selectedDate]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={stylesCreateTurno.box1}
            >
                <Box
                    sx={stylesCreateTurno.box2}
                >
                    <Box sx={stylesCreateTurno.box3}>
                        <IconButton onClick={handleGoBack} sx={{}}>
                            <ArrowBackIcon />
                        </IconButton>
                        {/* TODO DATO DINAMICO CATEDRAL */}
                        <Typography style={stylesCreateTurno.typography}>
                            {CreateTurnoEnum.CATEDRAL_DE_LA_PLATA}
                        </Typography>
                    </Box>
                    <Box sx={stylesCreateTurno.boxStaticDatePicker}>
                        <StaticDatePicker
                            defaultValue={null}
                            value={null}
                            onChange={handleDateChange}
                            disablePast
                            orientation={ScheduleAppointmentEnum.PORTRAIT}
                            minDate={currentDate}
                            shouldDisableDate={isDateDisabled}
                            showDaysOutsideCurrentMonth
                            sx={stylesCreateTurno.staticDatePicker}
                            slotProps={{
                                actionBar: { actions: [] },
                                day: ({ day }) => handleDayProperties(day, turno, selectedDate),
                            }}
                        />
                    </Box>

                </Box>
                <div style={stylesCreateTurno.divForm}>
                    <form onSubmit={handleSubmit}>
                        {
                            !isActividadParroquial &&
                            <Grid style={stylesCreateTurno.gridTipo}>
                                <Typography style={stylesCreateTurno.typographyTipo}>
                                    {CreateTurnoEnum.SELECCIONE_TIPO}
                                </Typography>
                                <Select
                                    labelId={ValuesEnum.TYPE_SELECT_LABEL}
                                    id={ValuesEnum.TYPE_SELECT}
                                    value={turno.type || ""}
                                    onChange={(event) => handleTipoChange(event, setTurno)}
                                    fullWidth
                                    size={Sizes.SMALL}
                                    style={stylesCreateTurno.selectTipo}
                                    disabled={turnoId ? true : false}
                                    required
                                >
                                    {opcionesTipo.map((opcion: any) => (
                                        <MenuItem key={opcion.value} value={opcion.value}>
                                            {opcion.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        }
                        <Grid sx={stylesCreateTurno.gridFormControl}>
                            <Typography style={stylesCreateTurno.typography}>
                                {CreateTurnoEnum.CRITERIO_FERIADOS}
                            </Typography>
                            <FormControl sx={stylesCreateTurno.formControlRadios}>
                                <RadioGroup
                                    aria-labelledby={ValuesEnum.DEMO_FORM_CONTROL_LABEL}
                                    name={ValuesEnum.CRITERY_FERIADOS}
                                    value={turno.criteryFeriados || ""}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (newValue === ValuesEnum.HORARIO_NORMAL || newValue === ValuesEnum.SIN_ACTIVIDAD) {
                                            setTurno({ ...turno, criteryFeriados: newValue, startHourSpecial: null, endHourSpecial: null });
                                        } else {
                                            setTurno({ ...turno, criteryFeriados: newValue });
                                        }
                                    }}
                                    sx={stylesCreateTurno.formRadioGroup}
                                >
                                    <FormControlLabel value={ValuesEnum.HORARIO_ESPECIAL} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={CreateTurnoEnum.HORARIOS_ESPECIALES} />
                                    <FormControlLabel value={ValuesEnum.HORARIO_NORMAL} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={CreateTurnoEnum.IGUAL_DIAS_HABILES} />
                                    <FormControlLabel value={ValuesEnum.SIN_ACTIVIDAD} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={CreateTurnoEnum.SIN_ACTIVIDAD} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Box sx={stylesCreateTurno.boxAvatars}>
                            {turno.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL && (
                                <>
                                    <TextField
                                        label={CreateTurnoEnum.HORA_INICIO}
                                        type={CreateTurnoEnum.TIME}
                                        value={turno.startHourSpecial || ""}
                                        onChange={(e) => {
                                            setTurno({ ...turno, startHourSpecial: e.target.value as any });
                                        }}
                                        margin={Sizes.NORMAL}
                                        size={Sizes.SMALL}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        label={CreateTurnoEnum.HORA_FIN}
                                        type={CreateTurnoEnum.TIME}
                                        value={turno.endHourSpecial || ""}
                                        onChange={(e) => {
                                            setTurno({ ...turno, endHourSpecial: e.target.value as any });
                                        }}
                                        margin={Sizes.NORMAL}
                                        size={Sizes.SMALL}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                        <Grid sx={stylesCreateTurno.gridFormControl}>
                            <Typography style={stylesCreateTurno.typography}>
                                {Periodicidad.PERIODICIDAD}
                            </Typography>
                            <FormControl sx={stylesCreateTurno.formControlRadios}>
                                <RadioGroup
                                    aria-labelledby={ValuesEnum.DEMO_FORM_CONTROL_LABEL}
                                    name={ValuesEnum.PERIODICITY}
                                    value={turno.periodicity || ""}
                                    onChange={(event) => setTurno({ ...turno, periodicity: event.target.value })}
                                    sx={stylesCreateTurno.formRadioGroup}
                                >
                                    <Box sx={stylesCreateTurno.boxRadios}>
                                        <FormControlLabel value={ValuesEnum.DIARIA} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={Periodicidad.DIARIA} />
                                        <Typography sx={stylesCreateTurno.smallText}>{Periodicidad.DIARA_DESCRIPCION}</Typography>
                                    </Box>
                                    <Box sx={stylesCreateTurno.boxRadios}>
                                        <FormControlLabel value={ValuesEnum.MENSUAL} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={Periodicidad.MENSUAL} />
                                        <Typography sx={stylesCreateTurno.smallText}>{Periodicidad.MENSUAL_DESCRIPCION}</Typography>
                                    </Box>
                                    <Box sx={stylesCreateTurno.boxRadios}>
                                        <FormControlLabel value={ValuesEnum.POR_FECHA} sx={stylesCreateTurno.formRadioGroupItem} control={<Radio />} label={Periodicidad.POR_FECHA} />
                                        <Typography sx={stylesCreateTurno.smallText}>{Periodicidad.POR_FECHA_DESCRIPCION}</Typography>
                                    </Box>

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Box sx={stylesCreateTurno.boxAvatars}>
                            {turno.periodicity === ValuesEnum.DIARIA && (
                                <>
                                    <Stack direction="row" spacing={2} style={stylesCreateTurno.stack}>
                                        {dias.map((dia) => (
                                            <Avatar
                                                key={dia}
                                                style={diasSeleccionados.includes(dia)
                                                    ? { ...stylesCreateTurno.avatars, ...stylesCreateTurno.avatarsSelects }
                                                    : stylesCreateTurno.avatars}
                                                onClick={() => handleSelectDay(dia, setDiasSeleccionados, setTurno)}
                                            >
                                                {dia}
                                            </Avatar>
                                        ))}
                                    </Stack>
                                    <Typography style={stylesCreateTurno.typographyStack}>
                                        {CreateTurnoEnum.SELECCIONAR_UNO_O_MAS_DIAS}
                                    </Typography>
                                </>
                            )}
                            {turno.periodicity === ValuesEnum.MENSUAL && (
                                <>
                                    <Stack direction="row" spacing={2} style={stylesCreateTurno.stack}>
                                        {dias.map((dia) => (
                                            <Avatar
                                                key={dia}
                                                style={diasSeleccionados.includes(dia)
                                                    ? { ...stylesCreateTurno.avatars, ...stylesCreateTurno.avatarsSelects }
                                                    : stylesCreateTurno.avatars}
                                                onClick={() => handleSelectDay(dia, setDiasSeleccionados, setTurno)}
                                            >
                                                {dia}
                                            </Avatar>
                                        ))}
                                    </Stack>
                                    <Select
                                        labelId={ValuesEnum.SEMANA_SELECT_LABEL}
                                        id={ValuesEnum.SEMANA_SELECT}
                                        value={turno.week || ""}
                                        onChange={(event) => handleSemanaChange(event, setTurno)}
                                        fullWidth
                                        size={Sizes.SMALL}
                                        style={stylesCreateTurno.semanaTipo}
                                    >
                                        {opcionesSemana.map((opcion: any) => (
                                            <MenuItem key={opcion.value} value={opcion.value}>
                                                {opcion.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography style={stylesCreateTurno.typographyStack}>
                                        {CreateTurnoEnum.SELECCIONAR_DIAS_SEMANAS}
                                    </Typography>
                                </>
                            )}
                            {turno.periodicity === ValuesEnum.POR_FECHA && (
                                <>
                                    <Select
                                        labelId={ValuesEnum.DIA_SELECT_LABEL}
                                        id={ValuesEnum.DIA_SELECT}
                                        value={turno.day || ""}
                                        onChange={(event) => handleDiaChange(setDiasSeleccionados, event, setTurno)}
                                        fullWidth
                                        size={Sizes.SMALL}
                                        style={stylesCreateTurno.fechaTipo}
                                    >
                                        {opcionesDiaNumericos.map((opcion: any) => (
                                            <MenuItem key={opcion.value} value={opcion.value}>
                                                {opcion.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography style={stylesCreateTurno.typographyStack}>
                                        {CreateTurnoEnum.SELECCIONAR_DIAS}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <Grid sx={stylesCreateTurno.gridHora}>
                            {(turno.type !== SacramentoEnum.Misa && !isActividadParroquial) && <TextField
                                id={ValuesEnum.CUPOS}
                                label={CreateTurnoEnum.CANTIDAD_CUPOS}
                                type={CreateTurnoEnum.NUMBER}
                                size={Sizes.SMALL}
                                value={turno.slots || ""}
                                style={stylesCreateTurno.gridCupos}
                                onChange={(e) => {
                                    setTurno({ ...turno, slots: e.target.value as any });
                                }}
                                required
                            />}
                            <FormControl style={stylesCreateTurno.formControlSector}>
                                <InputLabel style={stylesCreateTurno.imputsSectorSacerdote} id={ValuesEnum.SECTOR_LABEL}>
                                    {CreateTurnoEnum.SECTOR}
                                </InputLabel>
                                <Select
                                    labelId={ValuesEnum.SECTOR_ID_LABEL}
                                    id={ValuesEnum.SECTOR_ID}
                                    value={turno.sectorId || ""}
                                    onChange={(event) => handleSectorChange(event, setTurno, turno)}
                                    sx={stylesCreateTurno.sectorTipo}
                                    required
                                >
                                    {sectores.map((sector: any) => (
                                        <MenuItem key={sector.id} value={sector.id}>{sector.description}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl style={stylesCreateTurno.formControlSacerdote}>
                                <InputLabel style={stylesCreateTurno.imputsSectorSacerdote} id={ValuesEnum.SACERDOTE_LABEL}>
                                    {CreateTurnoEnum.SACERDOTE}
                                </InputLabel>
                                <Select
                                    labelId={ValuesEnum.SACERDOTE_ID_LABEL}
                                    id={ValuesEnum.SACEDORTE_ID}
                                    value={turno.sacerdoteId || ""}
                                    onChange={(event) => handleSacerdoteChange(event, setTurno)}
                                    required
                                    sx={stylesCreateTurno.sacerdoteTipo}
                                >
                                    {Array.isArray(sacerdotes) &&
                                        sacerdotes.map((sacerdote: any) => (
                                            <MenuItem key={sacerdote.id} value={sacerdote.id}>
                                                {sacerdote.nombre} {sacerdote.apellido}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Box sx={stylesCreateTurno.boxEnd}>
                            <TextField
                                label={CreateTurnoEnum.FECHA_DESDE}
                                type={CreateTurnoEnum.DATE}
                                value={turno.startTime ? moment(turno.startTime).format('YYYY-MM-DD') : ''}
                                onChange={(e) => {
                                    handleStartTimeChange(e.target.value as any, setSelectedDate, setTurno, turno);
                                }}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                label={CreateTurnoEnum.FECHA_HASTA}
                                type={CreateTurnoEnum.DATE}
                                value={turno.endTimeRepetition ? moment(turno.endTimeRepetition).format('YYYY-MM-DD') : ''}
                                onChange={(e) => handleEndTimeChange(e.target.value as any, setSelectedDate, setTurno, turno)}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                label={CreateTurnoEnum.HORA_INICIO}
                                type={CreateTurnoEnum.TIME}
                                value={turno.startHour || ""}
                                onChange={(e) => {
                                    setTurno({ ...turno, startHour: e.target.value as any });
                                }}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                label={CreateTurnoEnum.HORA_FIN}
                                type={CreateTurnoEnum.TIME}
                                value={turno.endHour || ""}
                                onChange={(e) => {
                                    if (e.target.value <= turno.startHour) {
                                        alert(
                                            "La hora de fin no puede ser anterior a la hora de inicio"
                                        );
                                    } else {
                                        setTurno({ ...turno, endHour: e.target.value as any });
                                    }
                                }}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </Box>
                        <Grid sx={stylesCreateTurno.gridButton}>
                            <Button
                                type={CreateTurnoEnum.SUBMIT}
                                variant={CreateTurnoEnum.CONTAINED}
                                color={CreateTurnoEnum.PRIMARY}
                            >
                                {turnoId ? CreateTurnoEnum.ACTUALIZAR_TURNO : CreateTurnoEnum.CREAR_TURNO}
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Box>
        </LocalizationProvider>
    );
};

export default CreateTurno;