// src/components/DatosMadre.tsx
import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { DataPersonal, DatosPersonales, Sizes, State } from '../../../enums/components/componentsEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import DocumentTypeComponent from '../../../components/autocomplete/documentType/DocumentTypeComponent';
import { getDocumentTypes } from '../../../utils/forms/formsUtils';
import { AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';
import { DatosMadreProps } from '../InterfacesActividadParroquial';

const DatosMadre: React.FC<DatosMadreProps> = ({
  participante,
  handleFormChange,
  disabledMother,
  inputLabelStyles,
  handleFetchPerson,
  resetStateValidator,
  setParticipante
}) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
          {AltaParticipanteTextos.DATOS_MADRE}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.NAME}_mother`}
          label={DatosPersonales.NAME}
          InputLabelProps={{ sx: inputLabelStyles }}
          type="text"
          value={participante?.mother?.name ?? ""}
          onChange={(e) => handleFormChange("mother.name", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.SURNAME}_mother`}
          label={DatosPersonales.SURNAME}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          value={participante?.mother?.surname ?? ""}
          onChange={(e) => handleFormChange("mother.surname", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={2}>
        <DocumentTypeComponent
          id={`${DataPersonal.DOCUMENT_TYPE}_mother`}
          InputLabelProps={{ sx: inputLabelStyles }}
          label="Tipo"
          style={{ borderRadius: "5px" }}
          countries={getDocumentTypes()}
          onFocus={resetStateValidator}
          onBlur={() => handleFetchPerson(participante.mother.document, participante.mother.document_type, "mother")}
          value={participante.mother.document_type ?? ""}
          onChange={(e: any, value: any) => {
            setParticipante((prev: any) => ({
              ...prev,
              mother: {
                ...prev.mother,
                document_type: value.id,
              }
            }));
          }}
          size={Sizes.SMALL}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id={`${DataPersonal.DOCUMENT}_mother`}
          label={DatosPersonales.NUMBER_DOCUMENT}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          value={participante.mother.document ?? ""}
          onBlur={() => handleFetchPerson(participante.mother.document, participante.mother.document_type, "mother")}
          onChange={(e) => handleFormChange("mother.document", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.BIRTHDATE}_mother`}
          label={DatosPersonales.FECHA_NACIMIENTO}
          type="date"
          value={participante.mother.birthdate ?? ""}
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          onChange={(e) => handleFormChange("mother.birthdate", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.EMAIL}
          label={DatosPersonales.EMAIL}
          type="text"
          autoComplete={State.OFF}
          value={participante.mother.email || ""}
          onChange={(e) => handleFormChange("mother.email", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          error={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.PHONE}
          label={DatosPersonales.TELEFONO}
          type="number"
          autoComplete={State.OFF}
          value={participante.mother.phone || ""}
          onChange={(e) => handleFormChange("mother.phone", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DatosMadre;
