import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { DataPersonal, IGLESIA, IGLESIA_CAPITALIZED, Locaciones, Locations, Sizes, State } from '../../../enums/components/componentsEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import LocationSelect from '../../../components/autocomplete/countries/LocationSelect';
import { DatosIglesiaProps } from '../InterfacesActividadParroquial';
import { ActividadParroquialTextos, AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';

const DatosIglesia: React.FC<DatosIglesiaProps> = ({
  participante,
  handleFormChange,
  disabledChurch,
  inputLabelStyles,
  handleCountryChange,
  handleProvinceChange,
  handlePartChange,
  countries,
  iglesiaProvinces,
  iglesiaParts,
  iglesias,
  iglesiaDirection,
  loadState,
  inAddPeopleToTurn = false,
}) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
          {AltaParticipanteTextos.IGLESIA_BAUTISMO}
        </Typography>
      </Grid>
      <Grid container columnSpacing={2} sx={PeopleActividadParroquialStyles.gridUbicacionIglesia}>
        <Grid item xs={6}>
          <LocationSelect
            id={Locations.COUNTRY}
            label={Locaciones.PAIS}
            options={countries ?? []}
            disabled={disabledChurch}
            onChange={(key, value) => handleCountryChange(key, value, IGLESIA)}
            size={Sizes.SMALL}
            loadState={loadState}
            errors={false}
            value={iglesiaDirection.country}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationSelect
            id={Locations.PROVINCE}
            label={Locaciones.PROVINCIA}
            loadState={loadState}
            options={iglesiaProvinces ?? []}
            disabled={disabledChurch}
            onChange={(key, value) => handleProvinceChange(key, value, IGLESIA)}
            size={Sizes.SMALL}
            errors={false}
            value={iglesiaDirection.province}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationSelect
            id={Locations.PART}
            label={Locaciones.PARTIDO}
            options={iglesiaParts ?? []}
            disabled={disabledChurch}
            loadState={loadState}
            onChange={(key, value) => handlePartChange(key, value, IGLESIA)}
            size={Sizes.SMALL}
            errors={false}
            value={iglesiaDirection.part}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationSelect
            id={IGLESIA}
            label={IGLESIA_CAPITALIZED}
            options={iglesias ?? []}
            disabled={disabledChurch}
            loadState={loadState}
            onChange={(key: any, value: any) => {
              handleFormChange("iglesia.idIgle", value.id);
            }}
            size={Sizes.SMALL}
            errors={false}
            helperText={""}
            value={iglesiaDirection.iglesia}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id={DataPersonal.LIBRO}
            label={DataPersonal.LIBRO}
            type="text"
            disabled={disabledChurch}
            value={participante.iglesia.book ?? ""}
            InputLabelProps={{ sx: inputLabelStyles }}
            style={{ borderRadius: "5px" }}
            onChange={(e) => handleFormChange("iglesia.book", e.target.value)}
            margin={Sizes.NORMAL}
            size={Sizes.SMALL}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id={DataPersonal.FOLIO}
            label={DataPersonal.FOLIO}
            type="text"
            disabled={disabledChurch}
            value={participante.iglesia.folio ?? ""}
            InputLabelProps={{ sx: inputLabelStyles }}
            style={{ borderRadius: "5px" }}
            onChange={(e) => handleFormChange("iglesia.folio", e.target.value)}
            margin={Sizes.NORMAL}
            size={Sizes.SMALL}
            fullWidth
          />
        </Grid>
        {
          !inAddPeopleToTurn &&
          <Grid item xs={12}>
            <TextField
              id={DataPersonal.BIRTHDATE}
              label={ActividadParroquialTextos.FechaBautismo}
              type="date"
              autoComplete={State.OFF}
              value={participante.ninio.dateSacramento}
              onChange={(e) => handleFormChange("ninio.dateSacramento", e.target.value)}
              name="dateSacramento"
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              disabled={disabledChurch}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default DatosIglesia;
