export interface Option {
  id: string;
  name: string;
}

export interface LocationSelectProps {
  id: string;
  options: Option[];
  size: "small" | "medium";
  label: string;
  onChange: (id: string, newValue: Option | null) => void;
  value: Option | null;
  errors: any;
  loadState: any;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface SelectedCountry {
  id: number;
  name: string;
}

export const LoadState = {
  DEFAULT: "DEFAULT",
  LOADED: "LOADED",
  EDITING: "EDITING",
};

export interface ArquidiocesisSelectProps {
  id: string;
  options: Option[];
  size: "small" | "medium";
  label: string;
  onChange: (id: string, newValue: Option | null) => void;
  value: Option | null;
  errors: any;
  loadState: any;
  helperText?: string;
  disabled?: boolean;
}
