import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import { compareOptionValue } from "../../../utils/document/documentTypeUtils";
import { SelectedCountry } from "../../../interfaces/components/interfacesAutoComplete";
import { getValidationColor } from "../../../utils/autocomplete/autoCompleteUtils";
import { NEW_PASSWORD, Sizes } from "../../../enums/components/componentsEnum";
import { inputLabelStyles } from "./DocumentTypeComponentStyles";

export default function DocumentTypeComponent({
  id,
  countries,
  size,
  label,
  onChange,
  value,
  errors,
  loadState,
  helperText,
  disabled = false,
  onBlur = () => {},
  onFocus = () => {},
  style,
}: any) {
  const [selectedCountry, setSelectedCountry] = useState<SelectedCountry | null>(
    //@ts-ignore
    countries.find((country) => country.id === value) || null
  );

  useEffect(() => {
    setSelectedCountry(countries.find((country: any) => country.id == value));
  }, [countries, value]);

  return (
    <>
      {countries.length > 0 ? (
        <Autocomplete
          id={id}
          options={countries}
          autoHighlight
          isOptionEqualToValue={compareOptionValue}
          getOptionLabel={(option: any) => option.name}
          value={selectedCountry} 
          onChange={(event, newValue) => {
            setSelectedCountry(newValue);
            if (newValue) {
              //@ts-ignore
              const selectedLabel = newValue.id;
              console.log(id, newValue);
              
              onChange(id, newValue);
            } else {
              onChange(id, "");
            }
          }}
          key={value?.id}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {
                //@ts-ignore
                option.name
              }
            </Box>
          )}
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
                autoComplete: NEW_PASSWORD,
              }}
              InputLabelProps={{ sx: inputLabelStyles }}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
              style={{
                border: getValidationColor(
                  errors,
                  errors?.[id],
                  value,
                  loadState?.current,
                ),
                backgroundColor: "white !important",
                borderRadius: "4px",
                ...style,
              }}
              size={size}
              margin={Sizes.NORMAL}
              error={errors}
              helperText={helperText}
            />
          )}
        />
      ) : null}
    </>
  );
}
