import jwt_decode, { JwtPayload } from "jwt-decode";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";

export const getDecodedToken = (): JwtPayload | null => {
  const access_token = localStorage.getItem("access_token");
  if (access_token && typeof access_token === "string" && access_token.trim() !== "") {
    try {
      return jwt_decode<JwtPayload>(access_token);
    } catch (error) {
      console.error("Error decoding JWT token:", error);
      return null;
    }
  }
  return null;
};

export const isTokenValid = (decodedToken: JwtPayload | null): boolean => {
  if (decodedToken) {
    const currentTime = Math.floor(Date.now() / 1000);
    return typeof decodedToken.exp === "number" && decodedToken.exp > currentTime;
  }
  return false;
};

export const isAuthenticated = (): boolean => {
  const decodedToken = getDecodedToken();
  const isValid = isTokenValid(decodedToken);
  if (!isValid) {
    ObservableService.notifyListeners(ObservableEvents.TOKEN_EXPIRED, null);
  }
  return isValid;
};

export const isSuperAdmin = (): boolean => {
  const decodedToken = getDecodedToken();
  if (isTokenValid(decodedToken)) {
    //@ts-ignore
    return decodedToken.systemRols?.includes("SuperAdmin");
  }
  return false;
};

export const isCoordinator = (): boolean => {
  const decodedToken = getDecodedToken();
  if (isTokenValid(decodedToken)) {
    //@ts-ignore
    return decodedToken.rolesIglesia?.some((roleIglesia: any) => roleIglesia.roles.includes("Coordinador"));
  }
  return false;
};

export const isAdmin = (): boolean => {
  const decodedToken = getDecodedToken();
  if (isTokenValid(decodedToken)) {
    //@ts-ignore
    return decodedToken.systemRols?.includes("Admin");
  }
  return false;
}

export const logout = (): void => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("iglesiaSelected");
  ObservableService.notifyListeners(ObservableEvents.LOGIN, false);
};
