import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import { SacramentoEnum } from "../../enums/SacramentoEnum";

export class TurnosHabilitadosService extends GenericService {
    constructor() {
        super();
    }

    async createEnabledUserForUpload(data: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                "/turnosHabilitados/create",
                data,
            );
            return response;
        } catch (error) {
            throw new Error("Error al crear el usuario habilitado para subir archivos");
        }
    }

    async getTurnsByCoordinatorId(page: number, pageSize: number, coordinatorId: number, ): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                `/turnosHabilitados/getTurnosHabilitadosByCoordinator/${page}/${pageSize}/${coordinatorId}`,
            );
            return response;
        } catch (error) {
            console.log("Error al obtener los turnos habilitados por coordinador");
        }
    }

    async addPeopleToTurn(data: any, idTurnoSimple: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                `/turnosHabilitados/addPeopleToTurn/${idTurnoSimple}`,
                data,
            );
            return response;
        } catch (error) {
            throw error;
        }
    }

    async addGroupPeopleToTurn(data: any, idTurnoSimple: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                `/turnosHabilitados/addGroupPeopleToTurn/${idTurnoSimple}`,
                data,
            );
            return response;
        } catch (error) {
            throw error;
        }
    }
}

const turnosHabilitadosService = new TurnosHabilitadosService();

export default turnosHabilitadosService;
