// src/components/DatosPadrinos.tsx
import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { DataPersonal, DatosPersonales, Sizes, State } from '../../../enums/components/componentsEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import DocumentTypeComponent from '../../../components/autocomplete/documentType/DocumentTypeComponent';
import { getDocumentTypes } from '../../../utils/forms/formsUtils';
import { AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';
import { DatosPadrinosProps } from '../InterfacesActividadParroquial';

const DatosPadrinos: React.FC<DatosPadrinosProps> = ({
  participante,
  handleFormChange,
  disabledGodparent,
  inputLabelStyles,
  handleFetchPerson,
  resetStateValidator,
  setParticipante
}) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
          {AltaParticipanteTextos.DATOS_PADRINOS}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.NAME}_godparent`}
          label={DatosPersonales.NAME}
          InputLabelProps={{ sx: inputLabelStyles }}
          type="text"
          value={participante?.godparent?.name ?? ""}
          onChange={(e) => handleFormChange("godparent.name", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.SURNAME}_godparent`}
          label={DatosPersonales.SURNAME}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          value={participante?.godparent?.surname ?? ""}
          onChange={(e) => handleFormChange("godparent.surname", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={2}>
        <DocumentTypeComponent
          id={`${DataPersonal.DOCUMENT_TYPE}_godparent`}
          InputLabelProps={{ sx: inputLabelStyles }}
          label="Tipo"
          style={{ borderRadius: "5px" }}
          countries={getDocumentTypes()}
          onFocus={resetStateValidator}
          onBlur={() => handleFetchPerson(participante.godparent.document, participante.godparent.document_type, "godparent")}
          value={participante.godparent.document_type ?? ""}
          onChange={(e: any, value: any) => {
            setParticipante((prev: any) => ({
              ...prev,
              godparent: {
                ...prev.godparent,
                document_type: value.id,
              }
            }));
          }}
          size={Sizes.SMALL}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id={`${DataPersonal.DOCUMENT}_godparent`}
          label={DatosPersonales.NUMBER_DOCUMENT}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          value={participante.godparent.document ?? ""}
          onBlur={() => handleFetchPerson(participante.godparent.document, participante.godparent.document_type, "godparent")}
          onChange={(e) => handleFormChange("godparent.document", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.BIRTHDATE}_godparent`}
          label={DatosPersonales.FECHA_NACIMIENTO}
          type="date"
          value={participante.godparent.birthdate ?? ""}
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          onChange={(e) => handleFormChange("godparent.birthdate", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.EMAIL}
          label={DatosPersonales.EMAIL}
          type="text"
          autoComplete={State.OFF}
          value={participante.godparent.email || ""}
          onChange={(e) => handleFormChange("godparent.email", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          error={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.PHONE}
          label={DatosPersonales.TELEFONO}
          type="number"
          autoComplete={State.OFF}
          value={participante.godparent.phone || ""}
          onChange={(e) => handleFormChange("godparent.phone", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DatosPadrinos;
