import { TableCell } from "@mui/material";
import moment from "moment";
import feriadosService from "../../services/feriados";

export function initializeFeriado() {
    return {
        date: new Date().toISOString().split('T')[0],
        motive: "",
        scope: "",
        country: {
            name: "",
            id: null,
        },
        province: {
            name: "",
            id: null,
        },
        part: {
            name: "",
            id: null,
        },
        locality: {
            name: "",
            id: null,
        },
        codPostal: 0,
        comments: "",
        transferTo: null as string | null,
    };
}

export const feriadosColumns = [
    {
        name: "Fecha",
        accessor: "date",
        customReder: (row: any, column: any) => {
            //@ts-ignore
            return (
                <TableCell>
                    {moment.utc(row[column.accessor]).format("YYYY-MM-DD")}
                </TableCell>
            );
        },
    },
    {
        name: "Fecha a transferir",
        accessor: "transferTo",
        customReder: (row: any, column: any) => {
            const dateValue = row[column.accessor];
            return (
                <TableCell>
                    {dateValue ? moment.utc(dateValue).format("YYYY-MM-DD") : "-"}
                </TableCell>
            );
        },
    },
    { name: "Motivo", accessor: "motive" },
    { name: "Alcance", accessor: "scope" },
    {
        name: "Lugar",
        accessor: "lugar",
        customReder: (row: any, column: any) => {
            let lugar;
            switch (row.scope) {
                case 'Nacional':
                    lugar = row.country ? row.country.name : 'No definido';
                    break;
                case 'Provincial':
                    lugar = row.province ? row.province.name : 'No definido';
                    break;
                case 'Local':
                    lugar = row.locality ? row.locality.name : 'No definido';
                    break;
                default:
                    lugar = 'No definido';
            }
            return (
                <TableCell>
                    {lugar.toString()}
                </TableCell>
            );
        },
    },
    { name: "Comentarios", accessor: "comments" },
    { name: "Estado", accessor: "state" },
];

export const initializeFiltersFeriados = () => {
    return [
        {
            param: "date",
            value: "",
        },
        {
            param: "scope",
            value: "",
        },
        {
            param: "state",
            value: "",
        },
        {
            param: "place",
            value: "",
        },
    ];
}

export const fetchFeriados = async (page: number, pageSize: number, filter?: string): Promise<any[]> => {
    try {
        const response = await feriadosService.getFeriados(page, pageSize, filter);
        return response;
    } catch (error) {
        console.log('error ', error);
        return [];
    }
};

export const scopeOptionsInitialize = [
    { id: "all", name: "Todos" },
    { id: "Nacional", name: "Nacional" },
    { id: "Provincial", name: "Provincial" },
    { id: "Local", name: "Local" },
];

export const stateOptionsInitialize = [
    { id: "all", name: "Todos" },
    { id: "Pendiente", name: "Pendiente" },
    { id: "Aprobado", name: "Aprobado" },
];

