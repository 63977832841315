import { useState } from "react";
import { useLocation } from "react-router-dom";
import administratorService from "../../services/administrator";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import {
  errorStyleOk,
  gridErrorStyle,
  errorStyle,
} from "./ResetPasswordPageStyles";
import { RoutesEnum } from "../../utils/RoutesEnum";
import { useNavigate } from "react-router-dom";

const isResetPassword = (location: ReturnType<typeof useLocation>) => {
  const pathname = location.pathname;
  return pathname.includes("reset_password");
};

const ResetPasswordPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();


  const handleResetPassword = async () => {
    try {
      await administratorService
        .resetPassword(token!, password)
        .then((response) => {
          setShowAlert(true);
          setMessage(response.message);
        })
        .catch((error: any) => {
          setError(error.message);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            navigate(RoutesEnum.PANEL);
          }, 6000);
        });
    } catch (error) {}
  };

  return (
    <div style={{ width: "70%" }}>
      <Grid container style={{ backgroundColor: "white", padding: "10%" }}>
        <Typography
          variant="subtitle1"
          sx={{ margin: "15px 0", fontWeight: 500, color: "#31876C" }}
        >
          {isResetPassword(location) ? "Restablecimiento de contraseña" : "Establece tu contraseña" }
        </Typography>

        <Grid item xs={12}>
          <TextField
            type="password"
            label="New Password"
            placeholder="New Password"
            fullWidth
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "2%" }}>
          <TextField
            type="password"
            fullWidth
            label="Confirm Password"
            autoComplete="off"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "20px" }}
          fullWidth
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>

        <Grid style={gridErrorStyle}>
          {showAlert &&
            (error ? (
              <Alert style={errorStyle} severity="error">
                {error}
              </Alert>
            ) : (
              <Alert style={errorStyleOk} severity="success">
                {message}
              </Alert>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordPage;
