import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { DatosPersonales, DataPersonal } from '../../../enums/components/componentsEnum';
import { Sizes } from '../../../enums/components/componentsEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import DocumentTypeComponent from '../../../components/autocomplete/documentType/DocumentTypeComponent';
import { getDocumentTypes } from '../../../utils/forms/formsUtils';
import LocationForm from '../../../components/forms/genericLocationForm/LocationForm';
import { AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';
import { DatosNinioProps } from '../InterfacesActividadParroquial';

const DatosNinio: React.FC<DatosNinioProps> = ({
    participante,
    handleFormChange,
    disabledNinio,
    inputLabelStyles,
    handleFetchPerson,
    resetStateValidator,
    setParticipante,
    birthNinioPlaces,
    onChangeBirthNinioPlaces,
    isFetch,
    loadState,
    isActivityParroquialCatequesis = false,
    isActivityParroquialMovimientoMisionero = false,
}) => {
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12}>
                <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                    {(isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero) ? AltaParticipanteTextos.DATOS_NINIO : AltaParticipanteTextos.DATOS_PARTICIPANTE}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id={DataPersonal.NAME}
                    label={DatosPersonales.NAME}
                    InputLabelProps={{ sx: inputLabelStyles }}
                    type="text"
                    value={participante?.ninio.name ?? ""}
                    onChange={(e) => handleFormChange("ninio.name", e.target.value)}
                    style={{ borderRadius: "5px" }}
                    margin={Sizes.NORMAL}
                    size={Sizes.SMALL}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id={DataPersonal.SURNAME}
                    label={DatosPersonales.SURNAME}
                    type="text"
                    InputLabelProps={{ sx: inputLabelStyles }}
                    value={participante?.ninio.surname ?? ""}
                    onChange={(e) => handleFormChange("ninio.surname", e.target.value)}
                    style={{ borderRadius: "5px" }}
                    margin={Sizes.NORMAL}
                    size={Sizes.SMALL}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={2}>
                <DocumentTypeComponent
                    id={DataPersonal.DOCUMENT_TYPE}
                    InputLabelProps={{ sx: inputLabelStyles }}
                    label="Tipo"
                    style={{ borderRadius: "5px" }}
                    countries={getDocumentTypes()}
                    onFocus={resetStateValidator}
                    onBlur={() =>
                        handleFetchPerson(participante.ninio.document, participante.ninio.document_type, "ninio")
                    }
                    value={participante.ninio.document_type ?? ""}
                    onChange={(e: any, value: any) => {
                        setParticipante((prev: any) => ({
                            ...prev,
                            ninio: {
                                ...prev.ninio,
                                document_type: value.id,
                            }
                        }));
                    }}
                    size={Sizes.SMALL}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id={DataPersonal.DNI}
                    label={DatosPersonales.NUMBER_DOCUMENT}
                    type="text"
                    InputLabelProps={{ sx: inputLabelStyles }}
                    style={{ borderRadius: "5px" }}
                    value={participante.ninio.document}
                    onBlur={() =>
                        handleFetchPerson(participante.ninio.document, participante.ninio.document_type, "ninio")
                    }
                    onChange={(e) => handleFormChange("ninio.document", e.target.value)}
                    margin={Sizes.NORMAL}
                    size={Sizes.SMALL}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id={DataPersonal.BIRTHDATE}
                    label={DatosPersonales.FECHA_NACIMIENTO}
                    type="date"
                    value={participante.ninio.birthdate ?? ""}
                    InputLabelProps={{ sx: inputLabelStyles }}
                    style={{ borderRadius: "5px" }}
                    onChange={(e) => handleFormChange("ninio.birthdate", e.target.value)}
                    margin={Sizes.NORMAL}
                    size={Sizes.SMALL}
                    fullWidth
                />
            </Grid>
            {
                (!isActivityParroquialCatequesis || !isActivityParroquialMovimientoMisionero) &&
                <Grid item xs={6}>
                    <TextField
                        id={DataPersonal.EMAIL}
                        label={DatosPersonales.MAIL_DE_CONTACTO}
                        value={participante.ninio.email}
                        type="text"
                        onChange={(e) => handleFormChange("ninio.email", e.target.value)}
                        fullWidth
                        style={{ borderRadius: "5px" }}
                        size={Sizes.SMALL}
                        margin={Sizes.NORMAL}
                        required
  
                    />
                </Grid>
            }
            {
                (!isActivityParroquialCatequesis && !isActivityParroquialMovimientoMisionero) &&
                <Grid item xs={6}>
                    <TextField
                        id={DataPersonal.PHONE}
                        label={DatosPersonales.CELULAR_DE_CONTACTO}
                        value={participante.ninio.phone}
                        type="number"
                        onChange={(e) => handleFormChange("ninio.phone", e.target.value)}
                        fullWidth
                        style={{ borderRadius: "5px" }}
                        size={Sizes.SMALL}
                        margin={Sizes.NORMAL}
                        required
  
                    />
                </Grid>
            }
            <LocationForm
                formData={birthNinioPlaces}
                onChange={onChangeBirthNinioPlaces}
                nacimiento={true}
                isFetch={isFetch}
                loadState={loadState}
            />
        </Grid>
    );
};

export default DatosNinio;
