import React from "react";
import { Box, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import GenericTable from "../../../components/genericTable/GenericTable";
import moment from "moment";
import { stylesListadoActividadParroquial } from "../ListadoActividadesParroquiales/ListadoActividadesParroquialesStyles";
import turnosHabilitadosService from "../../../services/turnosHabilitados/TurnosHabilitadosService";
import { getDecodedToken } from "../../../utils/auth";
import { getOnlyDate } from "../../../utils/datesUtils/dateUtils";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const CoordinatorListTurns = () => {
    const navigate = useNavigate();
    const [forceRefresh, setForceRefresh] = React.useState(new Date());
    const [totalTurnos, setTotalTurnos] = React.useState(0);
    const turnosHabilitadosColumns = [
        {
            name: "ID turno",
            accessor: "id",
            customReder: (row: any, column: any) => {
                return <TableCell>{row.turno?.id || '-'}</TableCell>;
            },
        },
        {
            name: "Fecha de inicio",
            accessor: "turno.startTime",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.turno?.startTime ? moment(getOnlyDate(row.turno?.startTime)).format("DD-MM-YYYY") : '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Fecha de fin",
            accessor: "turno.endTime",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.turno?.endTime ? moment(getOnlyDate(row.turno?.endTime)).format("DD-MM-YYYY") : '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Tipo de Turno",
            accessor: "turno.type",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.turno?.type || '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Nombre del colegio",
            accessor: "colegio.nombre",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.colegio?.nombre || '-'}
                    </TableCell>
                );
            },
        },
    ];

    /**
   * Actions for enabled turns
   */
    const turnosHabilitadosActions = [
        {
            icon: (row: any) => <PersonAddIcon />,
            onClick: (row: any) => {
                navigate(`/panel/actividad-parroquial/add-participants-to-turn/${row.turno?.id}/${row.turno?.type}/${row.coordinador?.id}`, {});
            },
        },
        {
            icon: (row: any) => row.encuentro && <GroupAddIcon />,
            onClick: (row: any) => {
              if (row.encuentro) {
                navigate(`/panel/actividad-parroquial/add-group-people-to-turn/${row.turno?.expecification?.id}/${row.turno?.id}/${row.turno?.type}/${row.coordinador?.id}`, {});
              }
            }
        },
    ];

    const fetchTurnosHabilitadosWithData = async (
        page: number,
        pageSize: number,
    ) => {
        const decodedToken = getDecodedToken();
        console.log('decodedToken', decodedToken);
        //@ts-ignore
        const response = await turnosHabilitadosService.getTurnsByCoordinatorId(page, pageSize, decodedToken?.id);
        console.log('response', response);
        const result = response;
        const total = response?.length;
        setTotalTurnos(total);

        return result;
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={stylesListadoActividadParroquial.box1}
        >
            <Box
                sx={stylesListadoActividadParroquial.boxPrincipalListado}
            >
                <div
                    style={stylesListadoActividadParroquial.divListado}
                ></div>
                <GenericTable<any>
                    filtersData={[{ param: "status", value: "pending" }]}
                    refresh={forceRefresh}
                    columns={turnosHabilitadosColumns}
                    actions={turnosHabilitadosActions}
                    title="turnos"
                    fetchData={fetchTurnosHabilitadosWithData}
                    totalTurnos={totalTurnos}
                    filters={() => {
                        return <></>;
                    }}
                />
            </Box>
        </Box>
    );
};

export default CoordinatorListTurns;
