import React from 'react';
import { Grid, Typography, FormControl, Select, MenuItem } from '@mui/material';
import { actividadParroquialTexts, CreateActividadParroquialEnum, ValuesEnum, ValuesLabelEnum } from '../../../enums/pages/CreateActividadParroquialEnum';
import { Sizes } from '../../../enums/components/componentsEnum';
import { stylesActividadParroquial } from '../createActividadParroquial/CreateActividadParroquialStyles';
import { ActividadParroquialInputsProps } from '../InterfacesActividadParroquial';

const ActividadParroquialInputs: React.FC<ActividadParroquialInputsProps> = ({
  actividadParroquialNombres,
  participante,
  handleItemChange,
  groups,
  turnosExpecification,
  disabledTurno = false,
}) => {
  return (
    <Grid container>
      <Typography style={stylesActividadParroquial.typographyTipo}>
        {CreateActividadParroquialEnum.SELECCIONE_LA_ACTIVIDAD_PARROQUIAL}
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              labelId={ValuesLabelEnum.NAME_ID}
              id={ValuesEnum.NAME_ID}
              value={participante.activity.nameId}
              onChange={(e) => {
                const selectedValue = e.target.value;
                const selectedItem = actividadParroquialNombres.find(categoria => categoria.id === selectedValue);
                const selectedName = selectedItem ? selectedItem.name : '';
                handleItemChange(selectedValue, 'nameId', selectedName);
              }}
              fullWidth
              size={Sizes.SMALL}
              required
              disabled={disabledTurno}
            >
              {actividadParroquialNombres.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                  {categoria.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* Selección de grupo */}
      <Typography style={stylesActividadParroquial.typographyGroup}>
        {CreateActividadParroquialEnum.SELECCIONE_GRUPO}
      </Typography>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Select
            value={participante.activity.groupId}
            onChange={(e) => handleItemChange(e.target.value, 'groupId')}
            displayEmpty
            fullWidth
            size={Sizes.SMALL}
            required
            disabled={disabledTurno}
          >
            {groups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))} 
          </Select>
        </FormControl>
      </Grid>
      <Grid style={stylesActividadParroquial.typographyTipoLast}>
        {/* @ts-ignore */}
        {participante?.activity?.groupId ? turnosExpecification?.parentTurnoId ? actividadParroquialTexts.GRUPO_WITH_MET_ASIGNED : actividadParroquialTexts.REMEMBER_SCHEDUL_THIS_MET_FOR_ACT_PARROQUIAL : null}
      </Grid>
    </Grid>
  );
};

export default ActividadParroquialInputs;
