import { format, parse } from "date-fns";
import { CreateActividadParroquialEnum } from "../../enums/pages/CreateActividadParroquialEnum";

export const assignResponseToParticipante = (formData: any, response: any, typePeople: string, bautismoSacramento: any = null) => {
    const updatedFormData = { ...formData }

    const filterResponse = (target: any, source: any) => {
        return Object.keys(target).reduce((acc, key) => {
            if (source.hasOwnProperty(key)) {
                acc[key] = source[key];
            }
            return acc;
        }, {} as any);
    };

    switch (typePeople) {
        case 'ninio':
            updatedFormData.ninio = {
                ...updatedFormData.ninio,
                ...filterResponse(updatedFormData.ninio, response),
                birthLocality: response.locality ?? null,
            };
            
            if (response.father) {
              updatedFormData.father = {
                ...updatedFormData.father,
                ...filterResponse(updatedFormData.father, response.father),
              };
            }
            if (response.mother) {
              updatedFormData.mother = {
                ...updatedFormData.mother,
                ...filterResponse(updatedFormData.mother, response.mother),
              };
            }
            if (bautismoSacramento && bautismoSacramento.sacramento && bautismoSacramento.sacramento.iglesia) {
              updatedFormData.iglesia = {
                ...updatedFormData.iglesia,
                ...bautismoSacramento.sacramento.iglesia,
              };
            }
            console.log('updatedFormData.ninio4444', updatedFormData.ninio);
            break;
        case 'father':
            updatedFormData.father = {
                ...updatedFormData.father,
                ...filterResponse(updatedFormData.father, response),
            };
            break;
        case 'mother':
            updatedFormData.mother = {
                ...updatedFormData.mother,
                ...filterResponse(updatedFormData.mother, response),
            };
            break;
        case 'godparent':
            updatedFormData.godparent = {
                ...updatedFormData.godparent,
                ...filterResponse(updatedFormData.godparent, response),
            };
            break;
        default:
            throw new Error(`Unknown typePeople: ${typePeople}`);
    }

    return updatedFormData;
};

export const optionsYears = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
];

export const initialParticipanteState = () => ({
    typeSchool: '',
    activity: {
        nameId: 0,
        groupId: 0,
        activityId: 0,
    },
    school: {
        schoolId: null,
        localityId: null,
        name: '',
        yearStudying: '',
        cue: '',
        religious: false,
    },
    ninio: {
        id: null,
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        birthLocality: null,
        dateSacramento: '',
        phone: '',
        email: '',
    },
    father: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    mother: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    godparent: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    iglesia: {
        idIgle: null,
        book: '',
        folio: '',
        dateBaptism: '',
    }
});

//TODO fetch colegios and cueColegios
export const colegiosMocked = [
    { id: 1, name: 'Colegio 1' },
    { id: 2, name: 'Colegio 2' },
    { id: 3, name: 'Colegio 3' },
];

export const cueColegiosMocked = [
    { id: 1, name: 'CUE 1' },
    { id: 2, name: 'CUE 2' },
    { id: 3, name: 'CUE 3' },
];
export interface HeaderPropsForActivityParroquial {
    handleGoBack: () => void;
}

export const initialFormStateEnabledUserForUpload = () => ({
    typeSchool: 'school',
    typeSacramento: '',
    coordinatorId: '',
    activity: {
        nameId: '',
        groupId: '',
        activityID: '',
    },
    school: {
        schoolId: '',
        name: '',
        cue: '',
        religious: false,
    },
    userEnabled: {
        id: '',
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        phone: '',
        email: '',
    },
});

export const assignResponseToUserEnabled = (formData: any, response: any) => {
    const updatedFormData = { ...formData }

    const filterResponse = (target: any, source: any) => {
        return Object.keys(target).reduce((acc, key) => {
            if (source.hasOwnProperty(key)) {
                acc[key] = source[key];
            }
            return acc;
        }, {} as any);
    };

    updatedFormData.userEnabled = {
        ...updatedFormData.userEnabled,
        ...filterResponse(updatedFormData.userEnabled, response),
        birthLocality: response.locality ?? null,
    };

    return updatedFormData;
}

export const handleCoordinatorChange = (event: any, setFormData: Function) => {
    setFormData((prevTurno: any) => ({ ...prevTurno, coordinatorId: event.target.value }));
};

/**
 * Sorts an array of objects by a specific property.
 * @param array - The array of objects to sort.
 * @param property - The property by which to sort.
 * @returns The sorted array.
 */
export const sortByProperty = (array: any[], property: any): any[] => {
    return array.sort((a, b) => {
        const propA = a[property];
        const propB = b[property];

        if (typeof propA === 'string' && typeof propB === 'string') {
            return propA.localeCompare(propB);
        }

        return 0;
    });
};

export const formatParticipanteData = (participante: any, isActivityParroquialCatequesis: any, isActivityParroquialMovimientoMisionero: any) => {
    const updatedNinio = {
        ...participante.ninio,
        locality: participante.ninio.birthLocality,
    };

    const participanteToSend = {
        typeSchool: participante.typeSchool,
        turnoExpecificationId: participante.activity.turnoExpecificationId,
        schoolId: participante.school.schoolId,
        activityNameId: participante.activity.nameId,
        yearStudying: participante.school.yearStudying,
        dateBaptism: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.ninio.dateSacramento : null,
        book: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.iglesia.book : null,
        folio: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.iglesia.folio : null,
        idIgle: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.iglesia.idIgle : null,
        religious: participante.school.religious,
        ninio: updatedNinio,
        father: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.father : null,
        mother: isActivityParroquialCatequesis || isActivityParroquialMovimientoMisionero ? participante.mother : null,
        godparent: isActivityParroquialCatequesis ? participante.godparent : null,
    };

    return participanteToSend;
};

export const initialParticipantToTurn = () => ({
    activity: {
        turnoExpecificationId: 0,
    },
    ninio: {
        id: null,
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        birthLocality: null,
        dateSacramento: '',
        phone: '',
        email: '',
    },
    father: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    mother: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    godparent: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    iglesia: {
        idIgle: null,
        book: '',
        folio: '',
        dateBaptism: '',
    }
});

export const formatParticipanteToTurnData = (participante: any, sacramentType: any, coordinatorId: any) => {
    const updatedNinio = {
        ...participante.ninio,
        locality: participante.ninio.birthLocality,
    };

    const participanteToSend = {
        sacramentType: sacramentType,
        ninio: updatedNinio,
        father: participante.father,
        mother: participante.mother,
        godparent: participante.godparent,
        book: participante.iglesia.book,
        folio: participante.iglesia.folio,
        idIgle: participante.iglesia.idIgle,
        dateBaptism:  participante.ninio.dateSacramento,
        coordinatorId: coordinatorId,
    };

    return participanteToSend;
};

export const sortByPropertyAndNameForSchool = (array: any[], property: any): any[] => {
    const exclusionPattern = /jardin\s*de\s*infantes/i;
    const uniqueNames = new Set();

    return array
        .filter(item => {
            if (exclusionPattern.test(item.name)) return false;
            if (uniqueNames.has(item.name.trim().toLowerCase())) return false;

            uniqueNames.add(item.name.trim().toLowerCase());
            return true;
        })
        .sort((a, b) => {
            const propA = a[property]?.trim().toLowerCase();
            const propB = b[property]?.trim().toLowerCase();

            if (typeof propA === 'string' && typeof propB === 'string') {
                return propA.localeCompare(propB);
            }

            return 0;
        });
};

export function updateActividadParroquialFlags(valueName: any, setCatequesis: any, setMovimientoMisionero: any) {
    console.log('valueName', valueName);
    if (valueName === CreateActividadParroquialEnum.CATEQUESIS) {
      setCatequesis(true);
      setMovimientoMisionero(false);
    } else if (valueName === CreateActividadParroquialEnum.MOVIMIENTOS_MISIONEROS) {
      setMovimientoMisionero(true);
      setCatequesis(false);
    } else {
      setCatequesis(false);
      setMovimientoMisionero(false);
    }
  }
  


