import React, { useEffect } from "react";
import { Box, IconButton, TableCell, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GenericTable from "../../../components/genericTable/GenericTable";
import DeleteIcon from "@mui/icons-material/Delete";
import turnoExpecificationService from "../../../services/turnosExpecification";
import moment from "moment";
import ObservableService, { ObservableEvents } from "../../../services/ObservableService";
import { convertUTCToLocal, getOnlyDate } from "../../../utils/datesUtils/dateUtils";
import feriadosService from "../../../services/feriados";
import turnoService from "../../../services/turnos";
import { isFeriado2 } from "../../../utils/scheduleAppointment/scheduleAppointmentUtils";
import { stylesListadoActividadParroquial } from "./ListadoActividadesParroquialesStyles";
import { ActividadParroquialRoutes, ActividadParroquialTextos, CreateActividadParroquialEnum } from "../../../enums/pages/CreateActividadParroquialEnum";
import NavLinkAdministratorGroups from "../createActividadParroquial/LinksAdministrationGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ActividadesParroquialesList = () => {
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [totalTurnos, setTotalTurnos] = React.useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [feriados, setFeriados] = React.useState<any[]>([]);

  useEffect(() => {
    feriadosService.getFeriadosForCalendar().then((response: any) => {
      setFeriados(response);
    });
  }, []);

  const turnosColumns = [
    {
      name: "Fecha",
      accessor: "startTime",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {`${moment(getOnlyDate(row.startTime)).format("DD-MM-YYYY")}`}
          </TableCell>
        );
      },
    },
    {
      name: "Hora de Inicio / Fin",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.startHour && row.endHour ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}` : ''}
          </TableCell>
        );
      },
    },
    {
      name: "Sector",
      accessor: "sector.description",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.sector ? row.sector.description : '-'}
          </TableCell>
        );
      },

    },
  ];

  /**
   * Actions for turnos
   */
  const turnosActions = [
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este evento?",
        );
        if (confirmDelete) {
          turnoService.deleteTurnoWithEventId(row.id).then((response: any) => {
            setForceRefresh(new Date());
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se elimino el evento correctamente",
            });
          });
        }
      },
    },
  ];

  const fetchTurnos = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    let expecificationId: any = id!;
    const response = await turnoExpecificationService.getTurnosbyId(expecificationId, page, pageSize);
    let turnos = response?.turnos;
    const total = response?.total;
    setTotalTurnos(total);

    turnos = turnos.filter((turno: any) => {
      const fechaTurno = getOnlyDate(turno.startTime);
      if (isFeriado2(fechaTurno, feriados)) {
        if (turno.expecification.criteryFeriados === 'sin_actividad') {
          return false;
        } else if (turno.expecification.criteryFeriados === 'horario_especial') {
          turno.startHour = turno.expecification.startHourSpecial;
          turno.endHour = turno.expecification.endHourSpecial;
        }
      }
      return true;
    });

    return turnos;
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{ display: "flex" }}
    >
      <Box
        sx={stylesListadoActividadParroquial.box2}
      >
        <Box sx={stylesListadoActividadParroquial.box3}>
          <IconButton onClick={handleGoBack} sx={stylesListadoActividadParroquial.iconButton}>
            <ArrowBackIcon />
          </IconButton>
          <Typography style={stylesListadoActividadParroquial.typography}>
            {CreateActividadParroquialEnum.CATEDRAL_DE_LA_PLATA}
          </Typography>
        </Box>

        <Box sx={stylesListadoActividadParroquial.containerLinks}>
          <Typography variant="subtitle1" sx={stylesListadoActividadParroquial.title}>
            {ActividadParroquialTextos.TituloAdministracionGrupo}
          </Typography>

          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.AltaActividad}
            label={ActividadParroquialTextos.AltaActividad}
            isActiveStyle={stylesListadoActividadParroquial.activeLink}
            defaultStyle={stylesListadoActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            label={ActividadParroquialTextos.ProgramarEncuentro}
            isActiveStyle={stylesListadoActividadParroquial.activeLink}
            defaultStyle={stylesListadoActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.ListadoEncuentros}
            label={ActividadParroquialTextos.ListadoEncuentros}
            isActiveStyle={stylesListadoActividadParroquial.activeLink}
            defaultStyle={stylesListadoActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.ListadoIntegrantes}
            label={ActividadParroquialTextos.ListadoIntegrantes}
            isActiveStyle={stylesListadoActividadParroquial.activeLink}
            defaultStyle={stylesListadoActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.AltaParticipantes}
            label={ActividadParroquialTextos.AltaParticipantes}
            isActiveStyle={stylesListadoActividadParroquial.activeLink}
            defaultStyle={stylesListadoActividadParroquial.italicLink}
          />
        </Box>
        <div></div>
      </Box>
      <Box
        sx={stylesListadoActividadParroquial.boxPrincipalListado}
      >
        <div
          style={stylesListadoActividadParroquial.divListado}
        ></div>
        <GenericTable<any>
          filtersData={[{ param: "status", value: "pending" }]}
          refresh={forceRefresh}
          columns={turnosColumns}
          actions={turnosActions}
          title="Actividades Parroquiales programadas"
          fetchData={fetchTurnos}
          totalTurnos={totalTurnos}
        />
      </Box>
    </Box>
  );
};

export default ActividadesParroquialesList;
