import { Box, Dialog, Typography } from "@mui/material";
import authService from "../../services/auth";
import { useEffect, useState } from "react";
import ProfilePage from "../Profile/ProfilePage";
import catedral from "../../assets/panel/catedra2.jpg";

const Panel = () => {
  const [userData, setUserData] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const { nombre, apellido } = authService.getUserData();
    setUserData(nombre + " " + apellido);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${catedral})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box sx={{ padding: "6%" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "white",
            paddingTop: "0px",
          }}
        >
          {authService.getIglesiaSelected()?.iglesia?.denomination}
        </Typography>
        <Typography
          onClick={() => {
            setDialogOpen(true);
          }}
          sx={{
            fontSize: "18px",
            cursor: "pointer",
            fontWeight: 400,
            color: "white",
            paddingTop: "10px",
          }}
        >
          {userData}
        </Typography>
        <Dialog
          open={dialogOpen}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "94%",
            backgroundColor: "transparent !important",
          }}
        >
          <ProfilePage
            isOwner
            custom={{
              fullScreen: "100%",
              backgroundColor: "transparent",
              handleGoBack: () => {
                setDialogOpen(false);
              },
            }}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default Panel;
