import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { stylesCreateFeriado } from "./CreateFeriadosStyles";
import { Alcance_Feriado, CreateFeriadoEnum } from "../../../enums/pages/CreateFeriadoEnum";
import { DAYS, DOCUMENT, Errors, Locaciones, Locations, ScheduleAppointmentEnum, Sizes, TEXT, TimeUnits } from "../../../enums/components/componentsEnum";
import LocationSelect from "../../../components/autocomplete/countries/LocationSelect";
import { LoadState } from "../../../enums/LoadStateEnum";
import { useQuery } from "react-query";
import { locacionesService } from "../../../services/locaciones";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import feriadosService from "../../../services/feriados";
import { addNotification } from "../../../utils/notifications";
import { queryClient } from "../../../App";
import { initializeFeriado } from "../../../utils/pages/CreateFeriadoUtils";
import { isSuperAdmin } from "../../../utils/auth";

const CreateFeriado = () => {
    const navigate = useNavigate();
    const [feriado, setFeriado] = useState(initializeFeriado);
    const loadState = useRef(LoadState.DEFAULT);
    const {
        data: countries,
        isLoading: _locacionesDataIsLoading,
        error: _locacionesDataError,
    } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
        locacionesService.getCountries()
    );
    const [provinces, setProvinces] = useState<any[]>([]);
    const [parts, setParts] = useState<any[]>([]);
    const [localities, setLocalities] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [hasUserSelectedDate, setHasUserSelectedDate] = useState(false);
    const [transferTo, setTransferTo] = useState<Moment | null>(null);
    const [hoveredDay, setHoveredDay] = useState<number | null>(null);
    const currentDate = moment();
    const location = useLocation();
    const feriadoId = location.state?.feriadoId;
    const [country, setCountry] = useState<any | null>(null);
    const [province, setProvince] = useState<any | null>(null);
    const [part, setPart] = useState<any | null>(null);
    const [locality, setLocality] = useState<any | null>(null);
    const [feriadosForCalendar, setFeriadosForCalendar] = useState<any[]>([]);

    useEffect(() => {
        if (feriadoId) {
            feriadosService.getFeriadoById(feriadoId)
                .then((response: any) => {
                    setFeriado(feriadoPrev => ({
                        ...feriadoPrev,
                        date: response.date,
                        motive: response.motive,
                        scope: response.scope,
                        country: response.country,
                        province: response.province,
                        part: response.part,
                        locality: response.locality,
                        codPostal: response.codPostal,
                        comments: response.comments,
                        transferTo: response.transferTo,
                    }));
                    setHasUserSelectedDate(true);
                    setSelectedDate(moment(response.date, 'YYYY-MM-DD'));
                    setCountry(response.country);
                    setProvince(response.province);
                    setPart(response.part);
                    setLocality(response.locality);
                })
                .catch((error: any) => {
                    console.log('Error al obtener los datos del feriado', error);
                });
        }
    }, [feriadoId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFeriado(prevState => ({ ...prevState, [name]: value }));
    };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (feriadoId) {
        feriadosService.updateFeriado(feriado, feriadoId)
            .then((result) => {
                addNotification(
                    queryClient,
                    "success",
                    CreateFeriadoEnum.FERIADO_ACTUALIZADO
                );
            }).catch((error) => {
                console.error(error);
                addNotification(
                    queryClient,
                    "error",
                    CreateFeriadoEnum.FERIADO_NO_ACTUALIZADO
                );
            });
    } else {
        feriadosService.createFeriado(feriado)
            .then((result) => {
                addNotification(
                    queryClient,
                    "success",
                    CreateFeriadoEnum.FERIADO_SUCESS
                );
            }).catch((error) => {
                console.error(error);
                addNotification(
                    queryClient,
                    "error",
                    CreateFeriadoEnum.FERIADO_ERROR
                );
            });
    }
};

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCountryChange = (key: any, value: any) => {
        if (key === DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        locacionesService.findProvincesByCountryId(value.id)
            .then((response: any) => {
                setProvinces(response);
                setFeriado({ ...feriado, country: { name: value.name, id: value.id } });
            })
            .catch((error: any) => {
                console.log(Errors.ERROR_GET_PROVINCES, error);
            })
    };

    const handleProvinceChange = (key: any, value: any) => {
        if (key === DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        locacionesService.findPartsByProvinceId(value.id)
            .then((response: any) => {
                setParts(response);
                if (feriado.scope === Alcance_Feriado.PROVINCIAL || feriado.scope === Alcance_Feriado.LOCAL) {
                    setFeriado({ ...feriado, province: { name: value.name, id: value.id } });
                }
            })
            .catch((error: any) => {
                console.log(Errors.ERROR_GET_PARTS, error);
            })
    };

    const handlePartChange = (key: any, value: any) => {
        if (key === DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        locacionesService.findPartWithLocalities(value.id).then((response: any) => {

            setLocalities(response.localities);
            if (feriado.scope === Alcance_Feriado.LOCAL) {
                setFeriado({ ...feriado, part: { name: value.name, id: value.id } });
            }

        }).catch((error: any) => {
            console.log(Errors.ERROR, error);
        })
    };

    const handleLocalityChange = (key: any, value: any) => {
        if (key === DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        if (feriado.scope === Alcance_Feriado.LOCAL) {
            setFeriado({ ...feriado, locality: { name: value.name, id: value.id } });
        }
    }

    const isDateDisabled = (date: Moment) => {
        const today = moment();
        const diffInDays = date.diff(today, DAYS);
        return diffInDays > 365;
    };

    const handleStartDateChange = (date: string) => {
        const momentDate = moment(date, 'YYYY-MM-DD');
        setSelectedDate(momentDate);
        setHasUserSelectedDate(true);
        setFeriado({ ...feriado, date: momentDate.format('YYYY-MM-DD') });
    };

    const handleTransferToDateChange = (date: string | null) => {
        if (date) {
            const momentDate = moment(date, 'YYYY-MM-DD');
            setTransferTo(momentDate);
            setFeriado({ ...feriado, transferTo: momentDate.format('YYYY-MM-DD') });
        }
    }

    useEffect(() => {
        const fetchFeriados = async () => {
            const response = await feriadosService.getFeriadosForCalendar();
            if (response) {
                setFeriadosForCalendar(response);
            }
        }
        fetchFeriados();
    }, []);

    const handleDateChange = (date: Moment | null) => {
        if (date) {
            setSelectedDate(date);
            setHasUserSelectedDate(true);
            setFeriado({ ...feriado, date: date.format('YYYY-MM-DD') });
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
                sx={stylesCreateFeriado.box1}
            >
                <Box
                    sx={stylesCreateFeriado.box2}
                >
                    <Box sx={stylesCreateFeriado.box3}>
                        <IconButton onClick={handleGoBack} sx={{}}>
                            <ArrowBackIcon />
                        </IconButton>
                        {/* TODO DATO DINAMICO CATEDRAL */}
                        <Typography style={stylesCreateFeriado.typography}>
                            {CreateFeriadoEnum.CATEDRAL_DE_LA_PLATA}
                        </Typography>
                    </Box>
                    <div></div>
                    <StaticDatePicker
                        defaultValue={moment.utc()}
                        value={hasUserSelectedDate ? selectedDate : moment.utc()}
                        onChange={handleDateChange}
                        disablePast
                        orientation={ScheduleAppointmentEnum.PORTRAIT}
                        minDate={currentDate}
                        shouldDisableDate={isDateDisabled}
                        showDaysOutsideCurrentMonth
                        sx={stylesCreateFeriado.staticDatePicker}
                        slotProps={{
                            actionBar: { actions: [] },
                            day: ({ day }) => {
                                const esFeriadoAprobado = feriadosForCalendar.some((feriado) => {
                                    const diaFeriado = moment.utc(feriado.transferTo ? feriado.transferTo : feriado.date).startOf(TimeUnits.DAYS);
                                    const diaActual = moment.utc(day).startOf(TimeUnits.DAYS);
                                    return diaActual.isSame(diaFeriado, TimeUnits.DAYS) && feriado.aproved;
                                });

                                const esFeriadoNoAprobado = feriadosForCalendar.some((feriado) => {
                                    const diaFeriado = moment.utc(feriado.transferTo ? feriado.transferTo : feriado.date).startOf(TimeUnits.DAYS);
                                    const diaActual = moment.utc(day).startOf(TimeUnits.DAYS);
                                    return diaActual.isSame(diaFeriado, TimeUnits.DAYS) && !feriado.aproved;
                                });

                                const isSelectedDay = moment.utc(day).isSame(
                                    selectedDate,
                                    TimeUnits.DAYS,
                                );
                                const isToday = moment.utc(day).isSame(
                                    moment.utc(),
                                    TimeUnits.DAYS,
                                );
                                const isDisabled =
                                    moment.utc(day).isBefore(currentDate, TimeUnits.DAYS) ||
                                    (!esFeriadoAprobado && !esFeriadoNoAprobado && !isSelectedDay && !isToday);

                                const style =
                                    !isSelectedDay && !isDisabled && !isToday
                                        ? esFeriadoAprobado ? { backgroundColor: "lightblue" }
                                            : esFeriadoNoAprobado ? { backgroundColor: "#f2b5b5" }
                                                : {}
                                        : {};

                                return {
                                    style,
                                    'data-selected-day': moment.utc(selectedDate),
                                    'data-hovered-day': hoveredDay,
                                    onPointerEnter: () => {
                                        if (moment.isMoment(selectedDate)) {
                                            setHoveredDay(parseInt(selectedDate.format('DD')));
                                        } else {
                                            console.error('selectedDate no es un objeto de Moment');
                                        }
                                    },
                                    onPointerLeave: () => setHoveredDay(null),
                                };
                            },
                        }}
                    />
                </Box>
                <div style={stylesCreateFeriado.divForm}>
                    <form onSubmit={handleSubmit}>
                        <Grid>
                            <Typography style={stylesCreateFeriado.typography}>
                                {CreateFeriadoEnum.MOTIVO_FERIADO}
                            </Typography>
                            <TextField
                                id={CreateFeriadoEnum.MOTIVO_FERIADO}
                                name="motive"
                                label={CreateFeriadoEnum.MOTIVO_FERIADO}
                                InputLabelProps={{ sx: stylesCreateFeriado.textField }}
                                type={TEXT}
                                disabled={false}
                                style={{
                                    borderRadius: "5px",
                                }}
                                value={feriado.motive}
                                onChange={handleChange}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                fullWidth
                                error={false}
                                helperText={""}
                                required
                            />
                        </Grid>
                        <Grid sx={stylesCreateFeriado.gridFormControl}>
                            <Typography style={stylesCreateFeriado.typography}>
                                {CreateFeriadoEnum.ALCANCE_FERIADO}
                            </Typography>
                            <FormControl sx={stylesCreateFeriado.formControlRadios} required>
                                <RadioGroup
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="position"
                                    value={feriado.scope}
                                    onChange={(event) => setFeriado({ ...feriado, scope: event.target.value })}
                                    sx={stylesCreateFeriado.formRadioGroup}
                                >
                                    {isSuperAdmin() && <FormControlLabel value={Alcance_Feriado.NACIONAL} sx={stylesCreateFeriado.formRadioGroupItem} control={<Radio />} label={Alcance_Feriado.NACIONAL} />}
                                    <FormControlLabel value={Alcance_Feriado.PROVINCIAL} sx={stylesCreateFeriado.formRadioGroupItem} control={<Radio />} label={Alcance_Feriado.PROVINCIAL} />
                                    <FormControlLabel value={Alcance_Feriado.LOCAL} sx={stylesCreateFeriado.formRadioGroupItem} control={<Radio />} label={Alcance_Feriado.LOCAL} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} >
                                <Typography style={stylesCreateFeriado.typography}>
                                    {CreateFeriadoEnum.LUGAR}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <LocationSelect
                                    id={Locations.COUNTRY}
                                    label={Locaciones.PAIS}
                                    options={countries ?? []}
                                    disabled={loadState.current === LoadState.LOADED}
                                    onChange={handleCountryChange}
                                    size={Sizes.SMALL}
                                    loadState={loadState}
                                    errors={false}
                                    value={country}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LocationSelect
                                    id={Locations.PROVINCE}
                                    label={Locaciones.PROVINCIA}
                                    loadState={loadState}
                                    options={provinces ?? []}
                                    disabled={feriado.scope === Alcance_Feriado.NACIONAL || loadState.current === LoadState.LOADED}
                                    onChange={handleProvinceChange}
                                    size={Sizes.SMALL}
                                    errors={false}
                                    value={province}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LocationSelect
                                    id={Locations.PART}
                                    label={Locaciones.PARTIDO}
                                    options={parts ?? []}
                                    disabled={feriado.scope === Alcance_Feriado.NACIONAL || feriado.scope === Alcance_Feriado.PROVINCIAL || loadState.current === LoadState.LOADED}
                                    loadState={loadState}
                                    onChange={handlePartChange}
                                    size={Sizes.SMALL}
                                    errors={false}
                                    value={part}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LocationSelect
                                    id={Locations.LOCALITY}
                                    label={Locaciones.LOCALIDAD}
                                    options={localities ?? []}
                                    disabled={feriado.scope === Alcance_Feriado.NACIONAL || feriado.scope === Alcance_Feriado.PROVINCIAL || loadState.current === LoadState.LOADED}
                                    loadState={loadState}
                                    onChange={handleLocalityChange}
                                    size={Sizes.SMALL}
                                    errors={false}
                                    value={locality}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={stylesCreateFeriado.gridDates}>
                            <FormControl >
                                <Typography style={stylesCreateFeriado.typography}>
                                    {CreateFeriadoEnum.FECHA}
                                </Typography>
                                <TextField
                                    label={CreateFeriadoEnum.FECHA}
                                    type="date"
                                    value={hasUserSelectedDate && selectedDate ? selectedDate.format('YYYY-MM-DD') : ''}
                                    onChange={(e) => {
                                        handleStartDateChange(e.target.value as any);
                                    }}
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </FormControl>
                            <FormControl>
                                <Typography style={stylesCreateFeriado.typography}>
                                    {CreateFeriadoEnum.TRANSFER_TO_OPTIONAL}
                                </Typography>
                                <TextField
                                    label={CreateFeriadoEnum.TRANSFER_TO}
                                    type="date"
                                    value={transferTo ? transferTo.format('YYYY-MM-DD') : ''}
                                    onChange={(e) => {
                                        handleTransferToDateChange(e.target.value as any);
                                    }}
                                    margin="normal"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                            
                        <Grid>
                            <TextField
                                id={CreateFeriadoEnum.COMENTARIOS}
                                label={CreateFeriadoEnum.COMENTARIOS}
                                InputLabelProps={{ sx: stylesCreateFeriado.textField }}
                                type={TEXT}
                                disabled={false}
                                name="comments"
                                style={stylesCreateFeriado.texfieldComments}
                                value={feriado.comments}
                                onChange={handleChange}
                                margin={Sizes.NORMAL}
                                size={Sizes.SMALL}
                                fullWidth
                                error={false}
                                helperText={""}
                            />
                        </Grid>
                        <Grid sx={stylesCreateFeriado.gridButton}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                               {feriadoId ? CreateFeriadoEnum.ACTUALIZAR_FERIADO : CreateFeriadoEnum.CREAR_FERIADO}
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Box>
        </LocalizationProvider>

    );
};

export default CreateFeriado;