import { useState, useEffect, useRef } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { convertUTCToLocal, getOnlyDate } from "../../../utils/datesUtils/dateUtils";
import { StylesEnabledUserForUpload, stylesScheduleOnEnabledUSer } from "./StylesEnabledUserForUpload";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { DataPersonal, DATE, DatosPersonales, DAYS, Directions, Errors, FLEX, FormTextSend, RESERVED, ScheduleAppointmentEnum, Sizes, State, TEXT, TimeUnits, TIPO, Turnos } from "../../../enums/components/componentsEnum";
import moment, { Moment } from "moment";
import calendarioService from "../../../services/calendario";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import { isFeriado } from "../../../utils/scheduleAppointment/scheduleAppointmentUtils";
import feriadosService from "../../../services/feriados";
import React from "react";
import { Slot } from "../../../interfaces/interfacesGeneral/interfacesSchedule";
import { ValuesEnum } from "../../../enums/pages/CreateTurnoEnum";
import LocationForm from "../../../components/forms/genericLocationForm/LocationForm";
import { inputLabelStyles, PeopleActividadParroquialStyles } from "../PeopleActividadParroquial/PeopleActividadParroquialStyles";
import { ActividadParroquialNombre, AllKeysForEnabledUser, Group, IglesiaAddress, NestedKeysForEnabledUser, SchoolType, TopLevelKeysForEnabledUser } from "../InterfacesActividadParroquial";
import colegioService from "../../../services/colegios/colegioService";
import { LoadState } from "../../../enums/LoadStateEnum";
import { AltaParticipanteTextos, CreateActividadParroquialEnum, EnabledUserEnums } from "../../../enums/pages/CreateActividadParroquialEnum";
import DocumentTypeComponent from "../../../components/autocomplete/documentType/DocumentTypeComponent";
import { getDocumentTypes } from "../../../utils/forms/formsUtils";
import { assignResponseToUserEnabled, handleCoordinatorChange, initialFormStateEnabledUserForUpload, sortByProperty } from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";
import peopleServie from "../../../services/people";
import { stylesCreateTurno } from "../../turnosV2/Createturno/stylesCreateTurno";
import actividadParroquialService from "../../../services/actividadParroquial/ActividadParroquialService";
import grupoParroquialService from "../../../services/grupoParroquial/GrupoParroquialService";
import { Coordinator } from "../../../utils/pages/ActividadParroquialUtils";
import { queryClient } from "../../../App";
import { addNotification } from "../../../utils/notifications";
import turnosHabilitadosService from "../../../services/turnosHabilitados/TurnosHabilitadosService";
import turnoExpecificationService from "../../../services/turnosExpecification";
import ActividadParroquialInputs from "../PeopleActividadParroquial/ActividadParroquialInputs";

interface EnabledUserForUploadProps {
    turnoType: SacramentoEnum;
}

const EnabledUserForUpload: React.FC<EnabledUserForUploadProps> = ({ turnoType }) => {
    const [formData, setFormData] = useState<any>(initialFormStateEnabledUserForUpload);
    const dateSelected = useRef<{
        day: Moment | null;
        start: string | null;
        end: string | null;
        turnoId: number | null;
        specificationId: number | null;
        limit: number | null;
        count: number | null;
    }>({
        day: null,
        start: null,
        end: null,
        turnoId: null,
        specificationId: null,
        limit: null,
        count: null,
    });
    const currentDate = moment();
    const [selectedTimeSlotsCalendar, setSelectedTimeSlotsCalendar] = useState<
        { startTime: string; endTime: string }[]
    >([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [selectedRange, setSelectedRange] = useState<Slot | null>(null);
    const [feriados, setFeriados] = useState<any>([]);
    const [hoveredDay, setHoveredDay] = React.useState<Moment | null>(null);
    const [colegios, setColegios] = useState<SchoolType[]>([]);
    const loadState = useRef(LoadState.DEFAULT);
    const [isFetch, setIsFetch] = useState(false);
    const [coordinators, setCoordinators] = useState<Coordinator[]>([]);
    const [actividadParroquialNombres, setActividadParroquialNombre] = useState<ActividadParroquialNombre[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [encuentro, setEncuentro] = useState([]);
    const [clicked, setClicked] = useState<boolean>(false);
    const [addressSchool, setAddressSchool] = useState<IglesiaAddress>({
        country: null,
        province: null,
        part: null,
        locality: null,
    });
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<
        {
            start: string;
            end: string;
            turnoId: number;
            specificationId: number;
            limit: number;
            count: number;
            startTime?: string;
            endTime?: string;
            criteryFeriados?: string;
            startHourSpecial?: string;
            endHourSpecial?: string;
        }[]
    >([]);
    const [disabledInputsUser, setDisabledInputsUser] = useState<boolean>(false);

    useEffect(() => {
        try {
            const formatDate = new Date(
                moment().format(ScheduleAppointmentEnum.FORMAT_DATE),
            );
            dateSelected.current = { ...dateSelected.current, day: currentDate };

            //TODO unify
            calendarioService.getTurnosByMonthV2(formatDate).then((eventsBusy) => {
                if (!!eventsBusy && eventsBusy.length > 0) {
                    setSelectedTimeSlotsCalendar(
                        eventsBusy
                            .filter((t: any) => t.type == turnoType)
                            .map((t) => {
                                return { startTime: getOnlyDate(t.startTime), endTime: getOnlyDate(t.endTime) };
                            }),
                    );
                }
            });

            calendarioService
                .getTurnosByDate(formatDate, turnoType)
                .then((eventsBusy) => {
                    setSelectedTimeSlots(eventsBusy);
                });

            feriadosService.getFeriadosForCalendar()
                .then((feriados) => {
                    setFeriados(feriados);
                });

            actividadParroquialService.getActividadNombres().then((response: any) => {
                const sortedResponse = sortByProperty(response, 'name');
                setActividadParroquialNombre(sortedResponse);
            }).catch((error: any) => {
                console.error(error);
            });

        } catch (error) {
            console.error(Errors.ERROR_FETCHING_AVAILABLE_DAYS, error);
        }
    }, []);

    const handleDateChange = async (date: Moment | null) => {
        if (date) {
            try {
                if (!date.isSame(selectedDate, 'day')) {
                    setClicked(false);
                }
                const formatDate = new Date(
                    date.format(ScheduleAppointmentEnum.FORMAT_DATE),
                );
                const eventsBusy = await calendarioService.getTurnosByDate(
                    formatDate,
                    turnoType,
                );
                setSelectedTimeSlots(eventsBusy);
                dateSelected.current = { ...dateSelected.current, day: date };
                setSelectedDate(date);
            } catch (error) {
                console.error(Errors.ERROR_FETCHING_AVAILABLE_DAYS, error);
            }
        }
    };

    const isDateDisabled = (date: Moment) => {
        const today = moment();
        const diffInDays = date.diff(today, DAYS);
        return diffInDays > 365;
    };

    const handleRangeChange = (
        start: string | undefined,
        end: string | undefined,
        turnoId: number,
        specificationId: number,
        limit: number,
        count: number,
        index: number,
    ) => {
        if (start !== undefined && end !== undefined) {
            setSelectedRange({
                start,
                end,
                index,
                turnoId,
                specificationId,
                limit,
                count,
            });
            dateSelected.current = {
                ...dateSelected.current,
                start: start,
                end: end,
                turnoId: turnoId,
                specificationId: specificationId,
                limit: limit,
                count: count,
            };
        }
    };

    const onChangeAddressIglesia = (key: string, value: any) => {
        const keyMapping: { [key: string]: string } = {
            birthLocality: 'locality',
            birthProvince: 'province',
            birthPart: 'part',
            nationality: 'country'
        };

        if (key === 'birthLocality') {
            colegioService.findByLocalityId(value.id)
                .then((response: any) => {
                    setColegios(response);
                })
                .catch((error: any) => {
                    console.log(Errors.ERROR_GET_SCHOOLS, error);
                });
        }

        const mappedKey = keyMapping[key] || key;

        setAddressSchool(prev => ({
            ...prev,
            [mappedKey]: value,
        }));
    };

    const resetStateValidator = (
        state: LoadState = LoadState.DEFAULT,
        isFetch = false,
    ) => {
        setIsFetch(isFetch);
        loadState.current = state;
    };

    const handleFetchPerson = async (dni: string, documentType: string) => {
        dni = dni ?? "";
        documentType = documentType ?? "";
        if (!!dni && dni.length > 0 && documentType.length > 0) {
            peopleServie
                .findPeople(dni, documentType)
                .then((response: any) => {
                    if (!!response) {
                        let updatedParticipante;
                        updatedParticipante = assignResponseToUserEnabled(formData, response);
                        setFormData(updatedParticipante);
                        resetStateValidator(LoadState.LOADED, true);
                        setDisabledInputsUser(true);
                    } else {
                        resetStateValidator();
                    }
                })
                .catch((error: any) => {
                    setDisabledInputsUser(false);
                    resetStateValidator();
                });
        } else {
            resetStateValidator();
        }
    };

    const handleItemChange = (value: any, type: "groupId" | "nameId" | 'turnoExpecificationId') => {
        if (type === "groupId") {
            setFormData((prev: any) => ({
                ...prev,
                activity: {
                    ...prev.activity,
                    groupId: value,
                }
            }));
            actividadParroquialService.findActividadByActivityNameIdAndGroupId(formData.activity.nameId, value).then((response: any) => {
                turnoExpecificationService.getEncuentroByActivityId(response.id).then((response: any) => {
                    setEncuentro(response[0]);
                })
                setCoordinators(response.users);
            }).catch((error: any) => {
                console.error('Error al buscar actividad parroquial', error);
            });
        } else if (type === "nameId") {
            setFormData((prev: any) => ({
                ...prev,
                activity: {
                    ...prev.activity,
                    nameId: value,
                }
            }));

            grupoParroquialService.getGrupoWithOnlyIdAndName(value).then((response: any) => {
                const sortedResponse = sortByProperty(response, 'name');
                setGroups(sortedResponse);
            }).catch((error: any) => {
                console.error(error);
            });
        } else if (type === "turnoExpecificationId") {
            setFormData((prev: any) => ({
                ...prev,
                activity: {
                    ...prev.activity,
                    turnoExpecificationId: value,
                }
            }));
        }
    };

    const handleFormChange = (key: AllKeysForEnabledUser, value: any) => {
        const keys = key.split('.') as [TopLevelKeysForEnabledUser, NestedKeysForEnabledUser?];
        const isDocumentType = keys[keys.length - 1] === 'document_type';

        if (keys.length === 1) {
            // Upload on top level
            setFormData((prev: any) => ({
                ...prev,
                [keys[0]]: isDocumentType ? value.id : value,
            }));
        } else if (keys.length === 2) {
            // Upload on nested level
            const [parentKey, childKey] = keys;
            setFormData((prev: any) => {
                const parentValue = prev[parentKey];
                if (typeof parentValue === 'object' && parentValue !== null) {
                    return {
                        ...prev,
                        [parentKey]: {
                            ...parentValue,
                            [childKey!]: isDocumentType ? value.id : value,
                        },
                    };
                } else {
                    return prev;
                }
            });
        }
    };

    function handleOnClick(
        timeSlot: any,
        index: any,
        handleRangeChange: any,
        clicked: any,
    ) {
        setClicked(!clicked);
        if (timeSlot.state !== "RESERVED") {
            handleRangeChange(
                timeSlot.start,
                timeSlot.end,
                timeSlot.turnoId,
                timeSlot.specificationId,
                timeSlot.limit,
                timeSlot.count,
                index,
            );
        }

    }

    const cleanValuesForType = (type: string) => {
        if (type === EnabledUserEnums.SCHOOL) {
            setFormData((prev: any) => ({
                ...prev,
                activity: {
                    nameId: '',
                    groupId: '',
                },
                coordinatorId: '',
            }));
        } else if (type === EnabledUserEnums.ACTIVIDAD_PARROQUIAL) {
            setFormData((prev: any) => ({
                ...prev,
                school: {
                    schoolId: '',
                    name: '',
                    yearStudying: '',
                    cue: '',
                    religious: false,
                },
                userEnabled: {
                    id: '',
                    name: '',
                    surname: '',
                    document_type: '',
                    document: '',
                    birthdate: '',
                    phone: '',
                    email: '',
                },
                addressSchool: {
                    country: null,
                    province: null,
                    part: null,
                    locality: null,
                },
            }));
        }
    }

    const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = {
            ...formData,
            date: dateSelected.current.day ? moment(dateSelected.current.day, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
            turnoId: dateSelected.current.turnoId,
            specificationId: dateSelected.current.specificationId,
            limit: dateSelected.current.limit,
            count: dateSelected.current.count,
            typeSacramento: turnoType,
        };

        await turnosHabilitadosService.createEnabledUserForUpload(data)
            .then((response: any) => {
                if (formData.typeSchool === EnabledUserEnums.SCHOOL) {
                    addNotification(
                        queryClient,
                        "success",
                        EnabledUserEnums.SUCCESS_SCHOOL
                    );
                } else if (formData.typeSchool === EnabledUserEnums.ACTIVIDAD_PARROQUIAL) {
                    addNotification(
                        queryClient,
                        "success",
                        EnabledUserEnums.SUCCESS_ACTIVIDAD_PARROQUIAL
                    );
                }
            })
            .catch((error: any) => {
                if (formData.typeSchool === EnabledUserEnums.SCHOOL) {
                    addNotification(
                        queryClient,
                        "error",
                        EnabledUserEnums.ERROR_SCHOOL
                    );
                } else if (formData.typeSchool === EnabledUserEnums.ACTIVIDAD_PARROQUIAL) {
                    addNotification(
                        queryClient,
                        "error",
                        EnabledUserEnums.ERROR_ACTIVIDAD_PARROQUIAL
                    );
                }
            });
    }

    return (
        <Box>
            <form onSubmit={handleSubmitForm} style={StylesEnabledUserForUpload.boxGeneral}>
                <Box sx={StylesEnabledUserForUpload.container}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid container style={StylesEnabledUserForUpload.grid}>
                            <Grid
                                item
                                lg={5}
                                justifyContent={Directions.CENTER}
                                display={FLEX}
                                alignContent={Directions.CENTER}
                                alignItems={Directions.CENTER}
                            >
                                <Box sx={StylesEnabledUserForUpload.box0}>
                                    <div style={{ justifyContent: Directions.CENTER }}>
                                        <Typography sx={StylesEnabledUserForUpload.typography}>
                                            {ScheduleAppointmentEnum.SELECCIONE_FECHA}
                                        </Typography>
                                        <StaticDatePicker
                                            defaultValue={moment()}
                                            onChange={handleDateChange}
                                            disablePast
                                            orientation={ScheduleAppointmentEnum.PORTRAIT}
                                            minDate={currentDate}
                                            shouldDisableDate={isDateDisabled}
                                            showDaysOutsideCurrentMonth
                                            sx={StylesEnabledUserForUpload.staticDatePicker}
                                            slotProps={{
                                                actionBar: { actions: [] },
                                                day: ({ day }) => {
                                                    const isInRange = selectedTimeSlotsCalendar.some((slot) => {

                                                        // Todo revisar
                                                        const startDay = moment(slot.startTime).startOf(
                                                            TimeUnits.DAYS,
                                                        );

                                                        const currentDay = moment(day).startOf(TimeUnits.DAYS);
                                                        return currentDay.isBetween(startDay, startDay, null, "[]");
                                                    });

                                                    const isSelectedDay = moment(day).isSame(
                                                        selectedDate,
                                                        TimeUnits.DAYS,
                                                    );
                                                    const isToday = moment(day).isSame(
                                                        moment(),
                                                        TimeUnits.DAYS,
                                                    );
                                                    const isDisabled =
                                                        moment(day).isBefore(currentDate, TimeUnits.DAYS)

                                                    const style =
                                                        isFeriado(day, feriados) && !isSelectedDay && !isDisabled && !isToday
                                                            ? { backgroundColor: "#ffcccc" }
                                                            : isInRange && !isSelectedDay && !isDisabled && !isToday
                                                                ? { backgroundColor: "lightblue" }
                                                                : {};

                                                    return {
                                                        style,
                                                        'data-selected-day': selectedDate,
                                                        'data-hovered-day': hoveredDay,
                                                        onPointerEnter: () =>
                                                            setHoveredDay(dateSelected.current.day),
                                                        onPointerLeave: () => setHoveredDay(null),
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item lg={7}>
                                <Typography sx={StylesEnabledUserForUpload.typography2}>
                                    {Turnos.TURNOS_DISPONIBLES}
                                </Typography>
                                <Box sx={StylesEnabledUserForUpload.box1}>
                                    <Grid container justifyContent={Directions.CENTER}>
                                        {selectedTimeSlots.map((timeSlot: any, index) => {
                                            const usarHorarioEspecial = isFeriado(selectedDate, feriados) && timeSlot.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL;

                                            return (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    justifyContent={Directions.CENTER}
                                                    key={index}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleOnClick(
                                                                timeSlot,
                                                                index,
                                                                handleRangeChange,
                                                                clicked,
                                                            )
                                                        }
                                                        style={stylesScheduleOnEnabledUSer(selectedRange, index, timeSlot, clicked).div}
                                                        className={
                                                            timeSlot.state == RESERVED
                                                                ? ""
                                                                : ScheduleAppointmentEnum.TURNO_DIV
                                                        }
                                                    >
                                                        <p>
                                                            {ScheduleAppointmentEnum.SECTOR_PUNTOS} {timeSlot.sector} -
                                                            {usarHorarioEspecial ? convertUTCToLocal(timeSlot.startHourSpecial) : convertUTCToLocal(timeSlot.start)} -
                                                            {usarHorarioEspecial ? convertUTCToLocal(timeSlot.endHourSpecial) : convertUTCToLocal(timeSlot.end)} -
                                                            {ScheduleAppointmentEnum.OCUPACION_PUNTOS} {timeSlot.count}/{timeSlot.limit}
                                                        </p>
                                                    </div>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                    {selectedTimeSlots.length === 0 && (
                                        <Typography>{ScheduleAppointmentEnum.NO_HAY_TURNOS}</Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <style>
                            {`
                                .turno-div:hover {
                                    background-color: #2C6B9C !important;
                                    color: gray;
                                }
                            `}
                        </style>
                    </LocalizationProvider>
                </Box>
                <Box sx={PeopleActividadParroquialStyles.divFormEnabledUser}>
                    <Grid item xs={12} >
                        <Typography style={PeopleActividadParroquialStyles.typographyuserEnabled}>
                            {AltaParticipanteTextos.COLEGIO_PARROQUIA_DE_CARGA}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={PeopleActividadParroquialStyles.gridFormControl}>
                        <FormControl component="fieldset" sx={PeopleActividadParroquialStyles.formControlRadios}>
                            <RadioGroup
                                row
                                name="typeSchool"
                                value={formData.typeSchool}
                                onChange={(e) => {
                                    handleFormChange("typeSchool", e.target.value);
                                    cleanValuesForType(e.target.value);
                                }}
                                sx={StylesEnabledUserForUpload.formRadioGroup}
                            >
                                <FormControlLabel required value="school" control={<Radio />} label="Colegio" sx={PeopleActividadParroquialStyles.formRadioGroupItem} />
                                <FormControlLabel required value="parroquia" control={<Radio />} label="Parroquia" sx={PeopleActividadParroquialStyles.formRadioGroupItem} />
                                <FormControlLabel required value="actividadParroquial" control={<Radio />} label="Actividad Parroquial" sx={PeopleActividadParroquialStyles.formRadioGroupItem} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={PeopleActividadParroquialStyles.gridFormControlChecked}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.school.religious}
                                    onChange={(e) => handleFormChange("school.religious", !formData.school.religious)}
                                    name="religious"
                                />
                            }
                            label="Religioso"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!formData.school.religious}
                                    onChange={(e) => handleFormChange("school.religious", !formData.school.religious)}
                                />
                            }
                            label="No religioso"
                        />
                    </Grid>
                    {
                        (formData.typeSchool === EnabledUserEnums.SCHOOL || formData.typeSchool === EnabledUserEnums.PARROQUIA) ?
                            <Box>
                                <Grid container columnSpacing={2}>
                                    <LocationForm
                                        formData={addressSchool}
                                        onChange={onChangeAddressIglesia}
                                        nacimiento={true}
                                        disabled={false}
                                        isFetch={false}
                                        loadState={loadState}
                                        isInActivityParroquial={true}
                                    />
                                </Grid>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={9}>
                                        <Typography style={PeopleActividadParroquialStyles.typographySchool}>
                                            {AltaParticipanteTextos.ESCUELA}
                                        </Typography>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                value={formData.school.name}
                                                onChange={(e) => {
                                                    const selectedSchool = colegios.find(option => option.name === e.target.value);
                                                    const cue = selectedSchool ? selectedSchool.cue : '';
                                                    setFormData((prev: any) => ({
                                                        ...prev,
                                                        school: {
                                                            ...prev.school,
                                                            name: e.target.value,
                                                            schoolId: selectedSchool?.id ?? null,
                                                            cue: cue,
                                                        }
                                                    }));
                                                }}
                                                name="school"
                                                size={Sizes.SMALL}
                                                required
                                            >
                                                {colegios &&
                                                    colegios.map((option) => (
                                                        <MenuItem key={option.id} value={option.name}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography style={PeopleActividadParroquialStyles.typographySchool}>
                                            {AltaParticipanteTextos.CUE}
                                        </Typography>
                                        <FormControl fullWidth>
                                            <TextField
                                                value={formData.school.cue}
                                                name="cue"
                                                size={Sizes.SMALL}
                                                disabled={true}
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} >
                                        <Typography style={PeopleActividadParroquialStyles.typographyuserEnabled}>
                                            {AltaParticipanteTextos.PERSONA_HABILITADA}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={DataPersonal.NAME}
                                            label={DatosPersonales.NAME}
                                            InputLabelProps={{ sx: inputLabelStyles }}
                                            type={TEXT}
                                            disabled={disabledInputsUser}
                                            value={formData?.userEnabled.name ?? ""}
                                            onChange={(e) =>
                                                handleFormChange("userEnabled.name", e.target.value)
                                            }
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={DataPersonal.SURNAME}
                                            label={DatosPersonales.SURNAME}
                                            type={TEXT}
                                            InputLabelProps={{ sx: inputLabelStyles }}
                                            value={formData?.userEnabled.surname ?? ""}
                                            disabled={disabledInputsUser}
                                            onChange={(e) =>
                                                handleFormChange("userEnabled.surname", e.target.value)
                                            }
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <DocumentTypeComponent
                                            id={DataPersonal.DOCUMENT_TYPE}
                                            InputLabelProps={{ sx: inputLabelStyles }}
                                            label={TIPO}
                                            disabled={disabledInputsUser}
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            countries={getDocumentTypes()}
                                            onFocus={() => resetStateValidator()}
                                            onBlur={() =>
                                                handleFetchPerson(formData.userEnabled.document, formData.userEnabled.document_type)
                                            }
                                            value={formData.userEnabled.document_type ?? ""}
                                            onChange={(e: any, value: any) => {
                                                setFormData((prev: any) => ({
                                                    ...prev,
                                                    userEnabled: {
                                                        ...prev.userEnabled,
                                                        document_type: value.id,
                                                    }
                                                }));
                                            }}
                                            size={Sizes.SMALL}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id={DataPersonal.DNI}
                                            label={DatosPersonales.NUMBER_DOCUMENT}
                                            type={TEXT}
                                            disabled={disabledInputsUser}
                                            InputLabelProps={{ sx: inputLabelStyles }}
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            value={formData.userEnabled.document}
                                            onBlur={(e) =>
                                                handleFetchPerson(formData.userEnabled.document, formData.userEnabled.document_type)
                                            }
                                            onChange={(e) =>
                                                handleFormChange("userEnabled.document", e.target.value)
                                            }
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={DataPersonal.BIRTHDATE}
                                            label={DatosPersonales.FECHA_NACIMIENTO}
                                            type={DATE}
                                            disabled={disabledInputsUser}
                                            value={formData.userEnabled.birthdate ?? ""}
                                            InputLabelProps={{ sx: inputLabelStyles }}
                                            style={{
                                                borderRadius: "5px",
                                            }}
                                            onChange={(e) =>
                                                handleFormChange("userEnabled.birthdate", e.target.value)
                                            }
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={DataPersonal.EMAIL}
                                            label={DatosPersonales.EMAIL}
                                            type={TEXT}
                                            autoComplete={State.OFF}
                                            value={formData.userEnabled.email || ""}
                                            onChange={(e) => handleFormChange("userEnabled.email", e.target.value)}
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                            error={false}
                                            disabled={disabledInputsUser}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={DataPersonal.PHONE}
                                            label={DatosPersonales.TELEFONO}
                                            type={DataPersonal.NUMBER}
                                            autoComplete={State.OFF}
                                            value={formData.userEnabled.phone || ""}
                                            onChange={(e) => handleFormChange("userEnabled.phone", e.target.value)}
                                            margin={Sizes.NORMAL}
                                            size={Sizes.SMALL}
                                            fullWidth
                                            disabled={disabledInputsUser}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            <Box>
                                <ActividadParroquialInputs
                                    actividadParroquialNombres={actividadParroquialNombres}
                                    participante={formData}
                                    handleItemChange={handleItemChange}
                                    groups={groups}
                                    turnosExpecification={encuentro}
                                    disabledTurno={false}
                                />
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Typography style={stylesCreateTurno.typographyCoordinator}>
                                                {CreateActividadParroquialEnum.SELECCIONE_COORDINADOR}
                                            </Typography>
                                            <Select
                                                labelId={ValuesEnum.COORDINATOR_ID_LABEL}
                                                id={ValuesEnum.COORDINATOR_ID}
                                                value={formData.coordinatorId || ""}
                                                onChange={(event) => handleCoordinatorChange(event, setFormData)}
                                                required
                                                sx={stylesCreateTurno.sacerdoteTipo}
                                            >
                                                {Array.isArray(coordinators) &&
                                                    coordinators.map((coordinator: any) => (
                                                        <MenuItem key={coordinator.id} value={coordinator.id}>
                                                            {coordinator.name} {coordinator.surname}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                    }
                </Box>
                <Grid sx={StylesEnabledUserForUpload.gridButton}>
                    <Button
                        type={FormTextSend.SUBMIT}
                        variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                        color={CreateActividadParroquialEnum.PRIMARY}
                        style={StylesEnabledUserForUpload.buttonSave}
                    >
                        {CreateActividadParroquialEnum.GUARDAR}
                    </Button>
                </Grid>
            </form >
        </Box >
    );
};

export default EnabledUserForUpload;
