import React, { useRef, useState } from 'react';
import { Box, Grid, Button, Typography, IconButton } from '@mui/material';
import { AddPeopleToTurnTexts, AddToPeopleStrings, CreateActividadParroquialEnum } from '../../../enums/pages/CreateActividadParroquialEnum';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DOCUMENT, Errors, FormTextSend, Locaciones } from '../../../enums/components/componentsEnum';
import { LoadState } from '../../../enums/LoadStateEnum';
import peopleServie from "../../../services/people";
import { assignResponseToParticipante, formatParticipanteToTurnData, initialParticipantToTurn } from '../../../utils/actividadParroquialUtils/UtilsActividadParroquial';
import { BirthPlaces, IglesiaDirection, ParticipantToTurn } from '../InterfacesActividadParroquial';
import { queryClient } from '../../../App';
import { addNotification } from '../../../utils/notifications';
import { inputLabelStyles } from '../PeopleActividadParroquial/PeopleActividadParroquialStyles';
import DatosNinio from '../PeopleActividadParroquial/DatosNinio';
import { AddPeopleToTurnStyles } from './AddPeopleToTurnStyles';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DatosPadre from '../PeopleActividadParroquial/DatosPadre';
import DatosMadre from '../PeopleActividadParroquial/DatosMadre';
import DatosPadrinos from '../PeopleActividadParroquial/DatosPadrinos';
import sacramentosService from '../../../services/sacramentos';
import turnosHabilitadosService from '../../../services/turnosHabilitados/TurnosHabilitadosService';
import { SacramentoEnum } from '../../../enums/SacramentoEnum';
import DatosIglesia from '../PeopleActividadParroquial/DatosIglesia';
import { locacionesService } from '../../../services/locaciones';
import { useQuery } from 'react-query';

const AddPeopleToTurn: React.FC = () => {
    const navigate = useNavigate();
    const [forceRender, setForceRender] = useState<Date>();
    const { id, type, coordinatorId } = useParams<{ id: string; type: SacramentoEnum, coordinatorId: any }>();
    const [isFetch, setIsFetch] = useState(false);
    const loadState = useRef(LoadState.DEFAULT);
    const [birthParticipantPlaces, setBirthParticipantPlaces] = useState<BirthPlaces>({
        nationality: null,
        birthProvince: null,
        birthPart: null,
        birthLocality: null,
    });
    let [participante, setParticipante] = useState<ParticipantToTurn>(initialParticipantToTurn());
    const location = useLocation();
    const [disabledParticipant, setDisabledParticipant] = useState(false);
    const [disabledFather, setDisabledFather] = useState(false);
    const [disabledMother, setDisabledMother] = useState(false);
    const [disabledGodparent, setDisabledGodparent] = useState(false);
    const [disabledChurch, setDisabledChurch] = useState(false);
    const [iglesias, setIglesias] = useState<any[]>([]);
    const [iglesiaProvinces, setIglesiaProvinces] = useState<any[]>([]);
    const [iglesiaParts, setIglesiaParts] = useState<any[]>([]);
    const [iglesiaDirection, setIglesiaDirection] = useState<IglesiaDirection>({
        iglesia: null,
        part: null,
        province: null,
        country: null,
      });
    const {
        data: countries,
        isLoading: locacionesDataIsLoading,
        error: locacionesDataError,
      } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
        locacionesService.getCountries()
      );

    const handleGoBack = () => {
        navigate(-1);
    };

    const resetStateValidator = (
        state: LoadState = LoadState.DEFAULT,
        isFetch = false,
    ) => {
        setIsFetch(isFetch);
        loadState.current = state;
        setForceRender(new Date());
    };

    const handleFetchPerson = async (dni: string, documentType: string, typePeople: any) => {
        dni = dni ?? "";
        documentType = documentType ?? "";
        if (!!dni && dni.length > 0 && documentType.length > 0) {
            peopleServie
                .findPeople(dni, documentType)
                .then((response: any) => {
                    if (!!response) {
                        let updatedParticipante: ParticipantToTurn;
                        switch (typePeople) {
                            case "ninio":
                                let Sacramento: any;
                                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                                let updatedParticipant = updatedParticipante?.ninio;
                                Sacramento = response.sacramentos?.find((sacramento: any) => sacramento.sacramento.type === type);

                                const iglesiaData = {
                                    idIgle: Sacramento?.sacramento?.iglesia?.id || null,
                                    book: Sacramento?.sacramento?.book || '',
                                    folio: Sacramento?.sacramento?.folio || '',
                                    dateBaptism: Sacramento?.sacramento?.startDate || '',
                                  };

                                setBirthParticipantPlaces({
                                    nationality: response?.locality?.part?.province?.country,
                                    birthProvince: response?.locality?.part?.province,
                                    birthPart: response?.locality?.part,
                                    birthLocality: response?.locality,
                                });

                                setIglesiaDirection({
                                    iglesia: Sacramento?.sacramento?.iglesia,
                                    part: Sacramento?.sacramento?.iglesia?.locality?.part,
                                    province: Sacramento?.sacramento?.iglesia?.locality?.part?.province,
                                    country: Sacramento?.sacramento?.iglesia?.locality?.part?.province?.country,
                                  });

                                setParticipante((prev: any) => ({
                                    ...prev,
                                    ninio: updatedParticipant,
                                    iglesia: iglesiaData,
                                }));

                                if (Sacramento?.sacramento?.iglesia?.id !== null) {
                                    setDisabledChurch(true);
                                }

                                setDisabledParticipant(true);
                                resetStateValidator(LoadState.LOADED, true);

                                sacramentosService.getGodfatherBySacramentoId(Sacramento.sacramento.id).then((response: any) => {

                                    setParticipante((prev) => ({
                                        ...prev,
                                        godparent: {
                                            name: response.name,
                                            surname: response.surname,
                                            document_type: response.document_type,
                                            document: response.document,
                                            birthdate: response.birthdate,
                                            email: response.email,
                                            phone: response.phone,
                                        }
                                    }));
                                    response.document !== "" && setDisabledGodparent(true);
                                }).catch((error: any) => {
                                    console.error('Error al traer padrino', error);
                                });

                                setParticipante((prev) => ({
                                    ...prev,
                                    ninio: updatedParticipante?.ninio,
                                    father: updatedParticipante?.father,
                                    mother: updatedParticipante?.mother,
                                }));
                                updatedParticipante.father.document !== "" && setDisabledFather(true);
                                updatedParticipante.mother.document !== "" && setDisabledMother(true);
                                break;
                            case "father":
                                console.log('Entre a father');
                                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                                setParticipante((prev) => ({
                                    ...prev,
                                    father: updatedParticipante.father,
                                }));
                                break;
                            case "mother":
                                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                                setParticipante((prev) => ({
                                    ...prev,
                                    mother: updatedParticipante.mother,
                                }));
                                break;
                            case "godparent":
                                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                                setParticipante((prev) => ({
                                    ...prev,
                                    godparent: updatedParticipante.godparent,
                                }));
                                break;
                            default:
                                throw new Error(`Unknown typePeople: ${typePeople}`);
                        }


                        resetStateValidator(LoadState.LOADED, true);
                    } else {
                        resetStateValidator();
                    }
                })
                .catch((error: any) => {
                    resetStateValidator();
                });
        } else {
            resetStateValidator();
        }
    };

    const onChangeBirthParticipantPlaces = (key: string, value: any) => {
        if (key === 'birthLocality') {
            setParticipante((prev: any) => ({
                ...prev,
                ninio: {
                    ...prev.ninio,
                    birthLocality: value.id,
                }
            }));
        }
    };

    const handleFormChange = (key: any, value: any) => {
        const keys = key.split('.');
        const isDocumentType = keys[keys.length - 1] === 'document_type';

        if (keys.length === 1) {
            // Upload on top level
            setParticipante(prev => ({
                ...prev,
                [keys[0]]: isDocumentType ? value.id : value,
            }));
        } else if (keys.length === 2) {
            // Upload on nested level
            const [parentKey, childKey] = keys as [keyof ParticipantToTurn, keyof ParticipantToTurn];
            setParticipante(prev => {
                const parentValue = prev[parentKey];
                if (typeof parentValue === 'object' && parentValue !== null) {
                    return {
                        ...prev,
                        [parentKey]: {
                            ...parentValue,
                            [childKey!]: isDocumentType ? value.id : value,
                        },
                    };
                } else {
                    return prev;
                }
            });
        }
    };

    const handleCountryChange = (key: any, value: any, type: string) => {
        if (key == DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        locacionesService.findProvincesByCountryId(value.id)
            .then((response: any) => {
                if (type === 'iglesia') {
                    setIglesiaProvinces(response);
                }
            })
            .catch((error: any) => {
                console.log(Errors.ERROR_GET_PROVINCES, error);
            })
    };

    const handleProvinceChange = (key: any, value: any, type: string) => {
        if (key == DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }

        locacionesService.findPartsByProvinceId(value.id)
            .then((response: any) => {
                if (type === 'iglesia') {
                    setIglesiaParts(response);
                }
            })
            .catch((error: any) => {
                console.log(Errors.ERROR_GET_PARTS, error);
            })
    };

    const handlePartChange = (key: any, value: any, type: string) => {
        if (key == DOCUMENT) {
            loadState.current = LoadState.DEFAULT
        }
        locacionesService.findPartWithLocalities(value.id).then((response: any) => {

            setIglesias(response.localities.flatMap((locality: any) => {
                const localityName = locality.name;
                return locality.iglesias.map((iglesia: any) => {
                    iglesia.name = `${iglesia.denomination}-(${localityName})`;
                    return iglesia;
                });
            }))
        })
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formattedData = formatParticipanteToTurnData(participante, type, coordinatorId);
        turnosHabilitadosService.addPeopleToTurn(formattedData, id)
            .then((response: any) => {
                addNotification(queryClient, "success", AddPeopleToTurnTexts.PARTICIPANTE_AÑADIDO_CORRECTAMENTE);
            })
            .catch((error: any) => {
                const errorMessage = error?.response?.data?.message || error?.response?.data?.errorCode || AddPeopleToTurnTexts.ERROR_AÑADIR_PARTICIPANTE;
                addNotification(queryClient, "error", errorMessage);
            });
    }

    return (
        <Box sx={AddPeopleToTurnStyles.divForm}>
            <form onSubmit={handleSubmit}>
                <Box sx={AddPeopleToTurnStyles.box2}>
                    <IconButton onClick={handleGoBack} sx={{}}>
                        <ArrowBackIcon />
                    </IconButton>

                    <Box sx={AddPeopleToTurnStyles.titleContainer}>
                        <Typography sx={AddPeopleToTurnStyles.typographyTitle}>
                            {AddToPeopleStrings.ADD_PEOPLE_TO_TURN}
                        </Typography>
                    </Box>
                </Box>
                <DatosNinio
                    participante={participante}
                    handleFormChange={handleFormChange}
                    disabledNinio={disabledParticipant}
                    inputLabelStyles={inputLabelStyles}
                    handleFetchPerson={handleFetchPerson}
                    resetStateValidator={resetStateValidator}
                    setParticipante={setParticipante}
                    birthNinioPlaces={birthParticipantPlaces}
                    onChangeBirthNinioPlaces={onChangeBirthParticipantPlaces}
                    isFetch={isFetch}
                    loadState={loadState}
                />

                <DatosIglesia
                    participante={participante}
                    handleFormChange={handleFormChange}
                    disabledChurch={disabledChurch}
                    inputLabelStyles={inputLabelStyles}
                    handleCountryChange={handleCountryChange}
                    handleProvinceChange={handleProvinceChange}
                    handlePartChange={handlePartChange}
                    countries={countries}
                    iglesiaProvinces={iglesiaProvinces}
                    iglesiaParts={iglesiaParts}
                    iglesias={iglesias}
                    iglesiaDirection={iglesiaDirection}
                    loadState={loadState}
                    inAddPeopleToTurn={true}
                />

                <DatosPadre
                    participante={participante}
                    handleFormChange={handleFormChange}
                    disabledFather={disabledFather}
                    inputLabelStyles={inputLabelStyles}
                    handleFetchPerson={handleFetchPerson}
                    resetStateValidator={resetStateValidator}
                    setParticipante={setParticipante}
                />

                <DatosMadre
                    participante={participante}
                    handleFormChange={handleFormChange}
                    disabledMother={disabledMother}
                    inputLabelStyles={inputLabelStyles}
                    handleFetchPerson={handleFetchPerson}
                    resetStateValidator={resetStateValidator}
                    setParticipante={setParticipante}
                />

                <DatosPadrinos
                    participante={participante}
                    handleFormChange={handleFormChange}
                    disabledGodparent={disabledGodparent}
                    inputLabelStyles={inputLabelStyles}
                    handleFetchPerson={handleFetchPerson}
                    resetStateValidator={resetStateValidator}
                    setParticipante={setParticipante}
                />

                <Grid sx={AddPeopleToTurnStyles.gridButton}>
                    <Button
                        type={FormTextSend.SUBMIT}
                        variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                        color={CreateActividadParroquialEnum.PRIMARY}
                        style={AddPeopleToTurnStyles.buttonSave}
                    >
                        {CreateActividadParroquialEnum.ADD_PARTICIPANT}
                    </Button>
                </Grid>
            </form>
        </Box>
    );
};

export default AddPeopleToTurn;