import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import ObservableService, { ObservableEvents } from "../ObservableService";

export class TurnoExpecificationService extends GenericService {
  constructor() {
    super();
  }

  async create(expecification: any, sectorId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        `/turnoExpecification/createTurnoExpecification?sectorId=${sectorId}`,
        expecification,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.data) {
        console.log("Error al crear la programación", error);
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error al crear turno.", error);

      }
    }
  }

  async createTurnoForActivity(expecification: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        `/turnoExpecification/createTurnoExpecificationAP`,
        expecification,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.data) {
        console.log("Error al crear la programación", error);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "error",
          message: error.response.data.message,
        });
      } else {
        throw new Error("Error al crear turno.", error);

      }
    }
  }

  async getAllWithPagination(page: number, pageSize: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/findAllTurnoExpecification/" + page + "/" + pageSize,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getAllWithoutActivityParroquialWithPagination(page: number, pageSize: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/getAllWithoutActivityParroquialWithPagination" + "/" + page + "/" + pageSize,
        {
          params: {
            page: page,
            pageSize: pageSize,
            filters: JSON.stringify({ parentId: null })
          }
        }
      );
      return response
    } catch (error) {
      console.log("Error al obtener las especificaciones sin actividad parroquial", error);
    }
  }

  async deleteExpecificacion(id: any) {
    return await this.delete(`/turnoExpecification/${id}`);
  }

  async updateExpecificacion(idTurno: any, expecificacion: any) {
    try {
      const response: AxiosResponse<any> = await this.put(
        "/turnoExpecification/updateTurnoExpecification/" + idTurno,
        expecificacion,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error al actualizar turno.", error);
      }
    }
  }
  
  async updateExpecificacionAP(parentId: any, expecificacion: any) {
    try {
      const response: AxiosResponse<any> = await this.put(
        "/turnoExpecification/updateTurnoExpecificationAP/" + parentId,
        expecificacion,
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.data) {
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "error",
          message: error.response.data.message,
        });
      } else {
        throw new Error("Error al actualizar turno.", error);
      }
    }
  }

  async getExpecificationById(id: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/getTurnoExpecificationById/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener la programación");
    }
  }

  async getTurnosExpecificationByParentId(id: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/getTurnosExpecificationByParentId/" + id,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener la programación");
    }
  }

  // Todo hay que pasar todo lo que se llama expecification a specification en front y back
  async getExpcificationForType(page: number, pageSize: number, type: String): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/getExpcificationForType/" + page + "/" + pageSize + "/" + type,
      );
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getExpcificationForTypeAndIdExpecification(page: number, pageSize: number, type: String, id: number | null): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turnoExpecification/getExpcificationForTypeAndIdExpecification/" + page + "/" + pageSize + "/" + type + "/" + id);
      return response;
    } catch (error) {
      console.log("Error al crear la(s) iglesia(s)");
    }
  }

  async getExcificationMisas(page: number, pageSize: number): Promise<any> {
    return this.getExpcificationForType(page, pageSize, SacramentoEnum.Misa);
  }

  async getSpecificationActividadesParroquiales(page: number, pageSize: number): Promise<any> {
    return this.getExpcificationForType(page, pageSize, SacramentoEnum.ActividadParroquial);
  }

  async getSpecificationActividadesParroquialesByIdExpecification(page: number, pageSize: number, id: number | null): Promise<any> {
    return this.getExpcificationForTypeAndIdExpecification(page, pageSize, SacramentoEnum.ActividadParroquial, id);
  }

  async getTurnosbyId(id: any, page: number, pageSize: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/turno/getTurnosByIdExpecification/" + id + "/" + page + "/" + pageSize,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener el turno");
    }
  }

  async getTurnoEspecificationForActivityId(activityId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/turnoExpecification/getTurnoEspecificationForActivityId/${activityId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al obtener la especificación del turno para la actividad", error);
      throw error;
    }
  }

  async getEncuentroByActivityId(activityId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/encuentroactividadparroquial/getTurnoEspecificationForActivityId/${activityId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error al obtener el encuentro para la actividad", error);
      throw error;
    }
  }

}

const turnoExpecificationService = new TurnoExpecificationService();

export default turnoExpecificationService;
