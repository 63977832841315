// context/todoContext.tsx
import * as React from "react";
import {
  createEvent,
  fetchEvents,
  deleteEvent,
  updateEvent,
} from "../service/CalendarService";
import iglesiasService from "../../../services/iglesias";
import { useEffect } from "react";

export const EventContext = React.createContext("");

const reformatItems = (events: any) => {
  if (!events) {
    console.warn('No events provided');
    return [];
  }

  return events.map(function (event: any) {
    //@ts-ignore
    var newObj = {};
    //@ts-ignore

    newObj["_id"] = event._id;
    //@ts-ignore

    newObj["title"] = event.title;
    //@ts-ignore

    newObj["bgColor"] = event.bgColor;

    //@ts-ignore
    newObj["turnoId"] = event.turnoId;
    //@ts-ignore

    newObj["description"] = event.description;
    //@ts-ignore
    newObj["type"] = event.type;
    //@ts-ignore

    newObj["hours"] = event.hours;

    //@ts-ignore
    newObj["group"] = event.group;

    //@ts-ignore
    newObj["count"] = event.count;
    //@ts-ignore
    newObj["turnoSimpleId"] = event.turnoSimpleId;
    //@ts-ignore

    newObj["start"] = new Date(Date.parse(event.start))
      .toISOString()
      .slice(0, 16);
    //@ts-ignore

    newObj["end"] = new Date(Date.parse(event.end)).toISOString().slice(0, 16);
    return newObj;
  });
};

const fixDatesAsIso = (event: any) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    start: starter.toISOString().slice(0, 16),
    end: ender.toISOString().slice(0, 16),
  };
};

const fixDatesAsTimestamps = (event: any) => {
  const starter = new Date(Date.parse(event.start));
  starter.setMinutes(starter.getMinutes() - starter.getTimezoneOffset());
  const ender = new Date(Date.parse(event.end));
  ender.setMinutes(ender.getMinutes() - ender.getTimezoneOffset());

  return {
    //@ts-ignore

    start: Date.parse(starter),
    //@ts-ignore

    end: Date.parse(ender),
  };
};

//@ts-ignore

const EventProvider = ({ children }) => {
  const [events, setEvents] = React.useState([]);
  const [eventsOriginal, setEventsOriginal] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [typesSelected, setTypesSelected] = React.useState([]);
  const [groupsSelected, setGroupsSelected] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState({
    _id: "",
    title: "",
    description: "",
    start: null,
    end: null,
    bgColor: "",
    type: null,
    turnoId: null
  });
  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = React.useState("");

  const changeSelectedGroups = (grps: any = []) => {
    setGroupsSelected(grps);
    //@ts-ignore
    setEvents(eventsOriginal.filter((e: any) => grps?.includes(e.group) && ([...typesSelected]?.includes(e.type) || e.type == null)));
  }

  const changeSelectedType = (types: any = []) => {
    setTypesSelected(types);
    //@ts-ignore
    setEvents(eventsOriginal.filter((e: any) => (types?.includes(e.type) || e.type == null) && (groupsSelected?.includes(e.group))));
  }

  const handleClickOpen = (event = null) => {
    
      if (event === null) return;
  
      try {
        setOpen(true);
        if (
          //@ts-ignore
          event.hasOwnProperty("start") &&
          //@ts-ignore
          event.hasOwnProperty("end") &&
          //@ts-ignore
          !event.hasOwnProperty("title") &&
          //@ts-ignore
          !event.hasOwnProperty("type") &&
          //@ts-ignore
          !event.hasOwnProperty("description")
        ) {
          // Timeslot Select
        } else {
          // Select Existing
          const stamps = fixDatesAsTimestamps(event);
          //@ts-ignore
          setSelectedEvent({ ...stamps, ...event });
          setFormType("show");
        }
      } catch (e) {
        //@ts-ignore
        alert("handleClickOpen: ", e);
      }
    };

  const handleClose = () => {
    setFormType("");
    //@ts-ignore

    setSelectedEvent(null);
    setOpen(false);
  };

  const predefinedColors = [
    '#266bef',
    '#368fd2',
    '#526bd2',
    '#1975d1',
    '#3a8dbe',
    '#2852a5',
    '#5da4dc',
    '#acd9e6',
    '#10e7e1',
    '#5ddde6',
    '#5a9de3',
    '#95aed2'
  ];
  
  let colorIndex = 0; // To cycle through the colors
  
  const generateRandomColor = () => {
    // Select the color from the predefined array
    const color = predefinedColors[colorIndex];
    // Increment the index for the next color
    colorIndex = (colorIndex + 1) % predefinedColors.length;
    return color;
  };

  const init = async () => {
    try {
      let selected: any = []
      const grps = await iglesiasService.getSectores().then((result) => {
        return result.sectores.map((sector: any) => {
          selected.push(sector.id)
          return { id: sector.id, title: sector.description, color: generateRandomColor() };
        })
      });
      selected.push(0)
      setGroupsSelected(selected);
      const events = await fetchEvents(grps);
      const newItems = reformatItems(events);
      setEvents(newItems);
      setEventsOriginal(newItems)
      setGroups(grps);
      const newTypes: any = events?.map((n: any) => n.type).filter((n) => !!n);
      //@ts-ignore
      setTypes(new Set(newTypes));
      //@ts-ignore
      setTypesSelected(new Set(newTypes))
      } catch (e) {
      alert(e);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const saveEvent = async (data: any) => {
    const payload = {
      title: data.title,
      description: data.description,
      bgColor: data.bgColor,
      start: data.start,
      end: data.end,
    };
    const adjustPayload = fixDatesAsTimestamps(payload);
    const newEvent = await createEvent({ ...adjustPayload, ...payload });
    //@ts-ignore

    const reformatItem = reformatItems([newEvent.event]);
    //@ts-ignore

    if (newEvent.success) {
      setEvents([
        //@ts-ignore

        ...events,
        //@ts-ignore

        {
          ...reformatItem[0],
        },
      ]);

      setEventsOriginal([
        //@ts-ignore

        ...events,
        //@ts-ignore

        {
          ...reformatItem[0],
        },
      ])
    }
    return newEvent;
  };

  const editEvent = async (data: any) => {
    const res = await updateEvent(selectedEvent._id, data);
    const reformatItem = fixDatesAsTimestamps(res.event);
    const foundEvent = events.filter((event) => {
      //@ts-ignore

      if (event._id === res.event._id) {
        //@ts-ignore

        const newObject = { ...reformatItem, ...event };
        const index = events.indexOf(event);
        events.splice(index, 1);
        //@ts-ignore

        setEvents([...events, newObject]);
        //@ts-ignore

        setEventsOriginal([...events, newObject]);
      }
    });
    if (foundEvent) {
      handleClose();
      init();
    }
    return reformatItem;
  };

  const removeEvent = async () => {
    const newEvent = await deleteEvent(selectedEvent._id);
    if (newEvent.success) {
      handleClose();
      init();
    }
    return newEvent;
  };

  return (
    <EventContext.Provider
      //@ts-ignore

      value={{
        events,
        groups,
        groupsSelected,
        open,
        saveEvent,
        editEvent,
        removeEvent,
        handleClickOpen,
        handleClose,
        init,
        selectedEvent,
        setSelectedEvent,
        formType,
        setFormType,
        changeSelectedGroups,
        typesSelected,
        changeSelectedType
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;

export function useEventContext() {
  return React.useContext(EventContext);
}
