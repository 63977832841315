import { ValidationMessagesEnum } from "../../utils/login/loginEnum";

export enum EmailValidationEnum {
  INVALID_MESSAGE = "Ingrese un email válido.",
}

export const DOCUMENT = "document";
export const IGLESIA = "iglesia";
export const IGLESIA_CAPITALIZED = "Iglesia";
export const ADD_PADRINO_MADRINA = "Agregar padrino o madrina";
export const CONTAINED = "contained";
export const GUARDAR = "Guardar";
export const TEXT = "text";
export const TIPO = "tipo";
export const DATE = "date";
export const ROLE = "role";
export const LABEL = "label";
export const NEW_PASSWORD = "new-password";
export const CONFIRM_PASSWORD = "confirm-password";
export const CONFIRMAR_CONTRASENA = "Confirmar contraseña";
export const PASSWORD = "password";
export const RELIGION = "Religion";
export const FECHA = "Fecha";
export const NUMERO_CAPITALIZED_OFF = "numero";
export const LOCACIONES_CAPITALIZED_OFF = "locaciones";
export const USER_TYPE = "user-type";
export const DIV = "div";
export const ROUTE_LOGIN = "/login";
export const LOGO = "logo";
export const SUB_MENU = "submenu";
export const SUB_MENU_GUION = "submenu-";
export const SUB_MENU_GUION_LINK = "submenu-link";
export const MENU_LINK = "menu-link";
export const ROW = "row";
export const FLEX = "flex";
export const FLEX_END = "flex-end";
export const NOTIFICATIONS = "notifications";
export const RESERVED = "RESERVED";
export const DAYS = "days";
export const ATRAS = "Atrás";
export const FINALIZAR = "Finalizar";
export const SIGUIENTE = "Siguiente";
export const NACIMIENTO = "nacimiento";

export enum RoleFormEnum {
  ROL_IN_IGLESIA = "Rol en la iglesia",
  SUBTITLE1 = "subtitle1",
  SELECCIONE = "Seleccione...",
  AGREGAR_IGLESIA = "Agregar iglesia",
}

export enum AdultFormEnum {
  MATCH_PEOPLE_WITH = "Se encontro una persona con",
}

export enum UserRoles {
  SACERDOTE = "Sacerdote",
  ADMINISTRATOR = "Administrator",
  SECRETARIA = "Secretaria",
  COORDINADOR = "Coordinador",
  ROL = "Rol",
  ADMIN = "Admin",
  SUPER_ADMIN = "SuperAdmin",

}

export enum Roles {
  PADRE = "Padre",
  MADRE = "Madre",
  PADRINO = "Padrino",
  MADRINA = "Madrina",
}

export enum RolesEnum {
  FATHER = "father",
  MOTHER = "mother",
  GODFATHER = "godfather",
  GODMOTHER = "godmother",
}

export enum Errors {
  ERROR = "error",
  ERROR_GET_COUNTRIES = "Error in get countries",
  ERROR_GET_PARTS = "Error in get parts",
  ERROR_GET_PROVINCES = "Error in get provinces",
  ERROR_GET_LOCALITIES = "Error in get localities",
  ERROR_FETCHING_AVAILABLE_DAYS = "Error fetching available days:",
  ERROR_GET_ARQUIDIOCESIS = "Error in get arquidiocesis",
  ERROR_GET_LOCACIONES = "Error in get locaciones",
  ERROR_GET_SCHOOLS = "Error in get schools",
}

export enum Locations {
  COUNTRY = "country",
  PROVINCE = "province",
  PART = "part",
  LOCALITY = "locality",
  BIRTH_PROVINCE = "birthProvince",
  BIRTH_PART = "birthPart",
  BIRTH_LOCALITY = "birthLocality",
}

export enum Locaciones {
  PAIS = "Pais",
  PROVINCIA = "Provincia",
  PARTIDO = "Partido",
  LOCALIDAD = "Localidad",
  LOCACIONES = "Locaciones",
  CIUDAD = "Ciudad",
  PAIS_NACIMIENTO = "Pais de nacimiento",
  PROVINCIA_NACIMIENTO = "Provincia de nacimiento",
  PARTIDO_NACIMIENTO = "Partido de nacimiento",
  LOCALIDAD_NACIMIENTO = "Localidad de nacimiento",
}

export enum Sizes {
  SMALL = "small",
  MEDIUM = "medium",
  NORMAL = "normal",
}

export enum InOrder {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum FormTextSend {
  SUBMIT = "submit",
  RESET = "reset",
}

export enum PersonalInfoId {
  NAME = "nombre",
  SURNAME = "apellido",
  EMAIL = "email",
  ID_ARQUIDIOCESIS_INCARDINADO = "idArquidiosesisIncardinado",
  DOCUMENT = "documento",
  NUMBER_DOCUMENT = "nro de documento",
  CELULAR = "celular",
  PAIS = "pais",
  CIUDAD = "ciudad",
  ESTADO_CIVIL = "estadoCivil",
  FECHA_NACIMIENTO = "fechaNacimiento",
  PROVINCIA = "provincia",
  PARTIDO = "partido",
  LOCALIDAD = "localidad",
}

export enum DatosPersonales {
  NAME = "Nombre",
  SURNAME = "Apellido",
  EMAIL = "Email",
  DOCUMENT = "Documento",
  NUMBER_DOCUMENT = "Nro de documento",
  CELULAR = "Celular",
  ESTADO_CIVIL = "Estado civil",
  FECHA_NACIMIENTO = "Fecha de nacimiento",
  CALLE = "Calle",
  DIRECCION = "Dirección",
  NUMERO = "Numero",
  NACIONALIDAD = "Nacionalidad",
  LUGAR_NACIMIENTO = "Lugar de nacimiento",
  TELEFONO = "Teléfono",
  CONTRASENA = "Contraseña",
  DATOS_PERSONALES = "Datos personales",
  ID_ARQUIDIOCESIS_INCARDINADO = "ID Arquidioseis Incardinado",
  FECHA_BAUTISMO = "Fecha de bautismo",
  MAIL_DE_CONTACTO = "Mail de contacto",
  CELULAR_DE_CONTACTO = "Celular de contacto",
}

export enum DataPersonal {
  NAME = "name",
  SURNAME = "surname",
  EMAIL = "email",
  DOCUMENT = "document",
  DOCUMENT_TYPE = "document_type",
  DNI = "dni",
  NUMBER = "number",
  PHONE = "phone",
  STATUS_CIVIL = "statusCivil",
  BIRTHDATE = "birthdate",
  STREET = "street",
  NATIONALITY = "nationality",
  FULL_NAME = "full-name",
  LAST_NAME = "last-name",
  PASSWORD = "password",
  FOLIO = "folio",
  LIBRO = "libro",
  BIRTHDATE_BAPTISM = "birthDateBaptism",
}

export enum State {
  OFF = "off",
  ON = "on",
}

export enum Status {
  SUCCESS = "success",
  ERROR = "error",
}

export enum AlertModalEnum {
  ALERT_MODAL_TITLE = "alert-modal-title",
  ALERT_MODAL_DESCRIPTION = "alert-modal-description",
  SUCCESS_MESSAGE = "Podrás encontrarlo en el sector Calendario. En caso que haya quedado incompleto podrás encontrarlo en el menú “Turnos Pendientes”. Para emitir un certificado es necesario completar los datos.",
  ERROR_MESSAGE = "Por favor, inténtalo nuevamente más tarde.",
  BACK_TO_HOME = "Volver al inicio",
}

export enum Sacramentos {
  BAUTISMO = "bautismo",
  CONFIRMACION = "confirmacion",
  MATRIMONIO = "matrimonio",
  COMUNION = "comunion",
}

export enum Sacraments {
  BAPTISM = "baptism",
  CONFIRMATION = "confirmation",
  MARRIAGE = "marriage",
  COMUNION = "comunion",
}

export enum SacramentoData {
  BOOK = "book",
  PAGE = "page",
  NUMBER = "number",
  FOLIO = "folio",
}

export enum SacramentoDatos {
  LIBRO = "Libro",
  PAGINA = "Pagina",
  NUMERO = "Numero",
  FOLIO = "Folio",
}

export enum Checks {
  CHECK_MOTHER = "checkMother",
  CHECK_FATHER = "checkFather",
}

export enum values {
  BOOLEAN = "boolean",
  STRING = "string",
}

export enum ChildFormEnum {
  ENCONTRO_PERSONA = "Se encontro una persona con",
  NUM = "N°",
}

export enum CivilRegistrationEnum {
  OFFICE = "office",
  CERTIFICATE = "certificate",
  ACTA = "Acta",
  SECTION = "section",
}

export enum CivilRegistracionEnum {
  OFICINA = "Oficina",
  CERTIFICADO = "Certificado",
  SECCION = "Seccion",
}

export enum Register {
  REGISTRATION_FAILED = "Registration failed",
  REGISTRARSE = "Registrarse",
}

export enum Urls {
  IGLESIA_UPLOAD_IMAGE = "/iglesia/upload-image/1",
}

export enum RegisterFormEnum {
  SUBTITLE1 = "subtitle1",
  TIPO_DOCUMENTO = "Tipo de documento",
  PARROQUIA_DONDE_COLABORA = "Parroquia en la que colabora",
  CHURCH_ID = "church-id",
  REGISTRO_CURAS_PARROQUOS = "Registro para Usuarios",
  REGISTRO_PARA_SACERDOTES = "Registro para Sacerdotes",
  ROL_SISTEMA = "Rol en el Sistema",
  ROL_IGLESIA = "Rol en la Iglesia",
  DIRECCION_IGLESIA = "Direccion de la Iglesia",
  SELECCIONE = "Seleccione...",
  LUGAR_NACIMIENTO = "Lugar de nacimiento",
}

export enum FILE {
  FILE = "file",
}

export enum NavbarEnum {
  MI_CUENTA = "Mi cuenta",
  INGRESAR = "Ingresar",
}

export enum Directions {
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
  CENTER = "center",
}

export const defaultErrorMessages = {
  minLength: ValidationMessagesEnum.MIN_LENGTH,
  minLowercase: ValidationMessagesEnum.MIN_LOWERCASE,
  minUppercase: ValidationMessagesEnum.MIN_UPPERCASE,
  minNumbers: ValidationMessagesEnum.MIN_NUMBERS,
  minSymbols: ValidationMessagesEnum.MIN_SYMBOLS,
};

export enum ScheduleAppointmentEnum {
  FORMAT_DATE = "YYYY-MM-DD",
}

export enum Turnos {
  TURNOS_DISPONIBLES = "Turnos disponibles",
  TURNOS = "Turnos",
  TURNOS_PENDIENTES = "Turnos pendientes",
}

export enum ScheduleAppointmentEnum {
  TURNO_DIV = "turno-div",
  SECTOR_PUNTOS = "sector:",
  OCUPACION_PUNTOS = "Ocupacion:",
  NO_HAY_TURNOS = "No hay turnos disponibles para este día.",
  SELECCIONE_FECHA = "Seleccione la fecha",
  PORTRAIT = "portrait",
  CAPTION = "caption",
}

export enum TimeUnits {
  DAYS = "days",
  MONTHS = "months",
  YEARS = "years",
}

export enum UnidadesTiempo {
  DIAS = "dias",
  MESES = "meses",
  ANOS = "anos",
}

export enum StepsEnum {
  DATOS_DE = "Datos de",
  DATOS_REGISTRO_CIVIL = "Datos del Registro Civil",
  CIVIL_REGISTRATION = "civilRegistration",
  CONTAINER = "container",
  PADRINO_MADRINA = "Padrino / Madrina",
  DATOS_BAUTISMO = "Datos del Bautismo",
}

export enum Create_Sacerdote {
  CREAR_SACERDOTE = "Crear sacerdote",
  CREAR_SACERDOTE_SISTEMA = "Crear sacerdote en el sistema",
  PAIS_RECOMENDADO = "Pais recomendado ",
  CIUDAD_RECOMENDADA = "Ciudad recomendada ",
  LUGAR_NACIMIENTO_RECOMENDADO_PART_ONE= "* Lugar de nacimiento sugerido: Pais:",
  LUGAR_NACIMIENTO_RECOMENDADO_PART_TWO= "- Ciudad:",
  
}

export enum Sacerdote_Shadow_Enum {
  CREATE_USER = "Crear usuario",
  ERROR_FINDING_SACERDOTE = "Error al buscar el sacerdote",
  BACK = "Volver",
  NAME = "Nombre",
  LAST_NAME = "Apellido",
  PRIESTS = "Sacerdotes",
}