import axios from "axios";
import calendarioService from "../../../services/calendario";
import feriadosService from "../../../services/feriados";
import { changeTimezoneToUTC, getCompletedDateUtcForCalendar } from "../../../utils/datesUtils/dateUtils";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";

const HOST = ""

export async function fetchEvents(groups) {
  return feriadosService.getFeriadosForCalendar().then((feriados) => {

    const feriadosDates = feriados?.map((f, index) => {
      return {
        _id: index,
        turnoId: f.id,
        start: changeTimezoneToUTC(getCompletedDateUtcForCalendar(f.transferTo ? f.transferTo : f.date, "00:00:00")),
        end: changeTimezoneToUTC(getCompletedDateUtcForCalendar(f.transferTo ? f.transferTo : f.date, "23:59:00")),
        allDay: true,
        group: 0,
        bgColor: "red",
        type: null,
        title: "Feriado",
      }
    })
    const feriadosDatesStrings = feriadosDates.map(date => date.start.toISOString().split('T')[0]);

    return calendarioService.getTurnosByMonthV2ForBigCalendar(new Date()).then((eventsBusy) => {
      if (!!eventsBusy && eventsBusy.length > 0) {

        let eventOfCalendar = (eventsBusy
          .map((t, index) => {
            // Convertir la fecha de interés a string en formato ISO y tomar solo la parte de la fecha
            const startDateString = new Date(t.startTime).toISOString().split('T')[0];
            const matchingGroup = groups.find(f => t.group === f.id);
            const title = t.type === SacramentoEnum.ActividadParroquial ? t.nameActivityParroquial : t.type;
            if (t.criteryFeriados === "horario_normal") {
              return {
                _id: index,
                turnoId: t.id,
                turnoSimpleId: t.turnoSimpleId,
                start: new Date(getCompletedDateUtcForCalendar(t.startTime, t.startHour)),
                end: new Date(getCompletedDateUtcForCalendar(t.endTime, t.endHour)),
                allDay: false,
                title: title,
                type: t.type,
                group: t.group,
                count: t.count,
                bgColor: matchingGroup? matchingGroup.color : "defaultColor",
              };
            } else
              if (feriadosDatesStrings.includes(startDateString)) {
                if (t.criteryFeriados === "horario_especial") {
                  return {
                    _id: index,
                    turnoId: t.id,
                    start: new Date(getCompletedDateUtcForCalendar(t.startTime, t.startHourSpecial)),
                    end: new Date(getCompletedDateUtcForCalendar(t.startTime, t.endHourSpecial)),
                    turnoSimpleId: t.turnoSimpleId,
                    allDay: false,
                    title: title,
                    type: t.type,
                    group: t.group,
                    count: t.count,
                    bgColor: matchingGroup ? matchingGroup.color : "defaultColor",
                  };
                
                } else if (t.criteryFeriados === "sin_actividad") {
                  return null;
                }
              } else {
                return {
                  _id: index,
                  turnoId: t.id,
                  start: new Date(getCompletedDateUtcForCalendar(t.startTime, t.startHour)),
                  end: new Date(getCompletedDateUtcForCalendar(t.endTime, t.endHour)),
                  allDay: false,
                  turnoSimpleId: t.turnoSimpleId,
                  title: title,
                  type: t.type,
                  group: t.group,
                  count: t.count,
                  bgColor: matchingGroup ? matchingGroup.color : "defaultColor",

                };
              }
              return null;
          })
        )
        feriadosDates.forEach((p) => {
          eventOfCalendar.push(p);
        });
        return eventOfCalendar.filter(p => p != null);
      }
    });
  });

}

export async function createEvent(eventInput) {
  /*const res = await axios.post(`${HOST}/api/v1/events`, eventInput);
  const event = res.data;
  console.log("createEvent: ", event);*/
  /*let dateRange = data.newDateRanges.map((range) => ({
    startDate: range.startDate.toISOString().split("T")[0],
    endDate: range.endDate.toISOString().split("T")[0],
    startHour: range.startTime,
    endHour: range.endTime,
    available: range.available,
  }));

  const requestData = {
    id: id,
    availableDates: { dateRange },
  };

    ExperiencesService.addAvailableDates(requestData).then((response) => {
      return {_id:10, ...eventInput}
    });*/
  //event;
}

export async function showEvent(id) {
  const res = await axios.get(`${HOST}/api/v1/events/${id}`);
  const event = res.data;
  return event;
}

export async function updateEvent(id, eventInput) {
  const res = await axios.put(`${HOST}/api/v1/events/${id}`, eventInput);
  const event = res.data;
  return event;
}

export async function deleteEvent(id) {
  const res = await axios.delete(`${HOST}/api/v1/events/${id}`);
  const event = res.data;
  return event;
}
