import React from 'react';
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Select, MenuItem, TextField } from '@mui/material';
import { ActividadParroquialTextos, AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';
import { Sizes } from '../../../enums/components/componentsEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import LocationForm from '../../../components/forms/genericLocationForm/LocationForm';
import { CargaEscuelaProps } from '../InterfacesActividadParroquial';

const CargaEscuela: React.FC<CargaEscuelaProps> = ({
    participante,
    handleFormChange,
    disabledSchool,
    addressIglesia,
    onChangeAddressIglesia,
    isFetch,
    loadState,
    colegios,
    setParticipante,
    optionsYears,
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" style={PeopleActividadParroquialStyles.typographyCarga}>
                    {ActividadParroquialTextos.CargaDeParticipante}
                </Typography>

                <FormControl component="fieldset" sx={PeopleActividadParroquialStyles.formControlRadios} disabled={disabledSchool}>
                    <RadioGroup
                        row
                        name="typeSchool"
                        value={participante.typeSchool}
                        onChange={(e) => handleFormChange("typeSchool", e.target.value)}
                        sx={PeopleActividadParroquialStyles.formRadioGroup}
                    >
                        <FormControlLabel
                            required
                            value="school"
                            control={<Radio />}
                            label="Colegio"
                            sx={PeopleActividadParroquialStyles.formRadioGroupItem}
                        />
                        <FormControlLabel
                            required
                            value="Parroquia"
                            control={<Radio />}
                            label="Parroquia"
                            sx={PeopleActividadParroquialStyles.formRadioGroupItem}
                        />
                    </RadioGroup>
                </FormControl>

                <Grid item xs={12} sx={PeopleActividadParroquialStyles.gridFormControlChecked}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={participante.school.religious}
                                onChange={(e) => handleFormChange("school.religious", !participante.school.religious)}
                                name="religious"
                                disabled={disabledSchool}
                            />
                        }
                        label="Religioso"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!participante.school.religious}
                                onChange={(e) => handleFormChange("school.religious", !participante.school.religious)}
                                disabled={disabledSchool}
                            />
                        }
                        label="No religioso"
                    />
                </Grid>
            </Grid>

            {/* Formulario de localización */}
            <Grid container columnSpacing={2} style={PeopleActividadParroquialStyles.gridContainerParticipanteForm}>
                <LocationForm
                    formData={addressIglesia}
                    onChange={onChangeAddressIglesia}
                    nacimiento={true}
                    isFetch={isFetch}
                    loadState={loadState}
                    isInActivityParroquial={true}
                    disabled={disabledSchool}
                />

                {/* Escuela */}
                <Grid item xs={12}>
                    <Typography style={PeopleActividadParroquialStyles.typographySchool}>
                        {AltaParticipanteTextos.ESCUELA}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <Select
                            value={participante.school.name}
                            onChange={(e) => {
                                const selectedSchool = colegios.find(option => option.name === e.target.value);
                                const cue = selectedSchool ? selectedSchool.cue : '';
                                setParticipante((prev: any) => ({
                                    ...prev,
                                    school: {
                                        ...prev.school,
                                        name: e.target.value,
                                        schoolId: selectedSchool?.id ?? null,
                                        cue: cue,
                                    }
                                }));
                            }}
                            name="school"
                            size={Sizes.SMALL}
                            required
                            disabled={disabledSchool}
                        >
                            {colegios &&
                                colegios.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Año cursando y CUE */}
                <Grid item xs={6}>
                    <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
                        {AltaParticipanteTextos.ANIO_CURSANDO}
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={participante.school.yearStudying}
                            onChange={(e) => handleFormChange("school.yearStudying", e.target.value)}
                            name="yearStudying"
                            size={Sizes.SMALL}
                            disabled={disabledSchool}
                        >
                            {optionsYears &&
                                optionsYears.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
                        {AltaParticipanteTextos.CUE}
                    </Typography>
                    <FormControl fullWidth>
                        <TextField
                            value={participante.school.cue}
                            name="cue"
                            size={Sizes.SMALL}
                            disabled={true}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CargaEscuela;
