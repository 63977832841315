import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class GrupoParroquialService extends GenericService {
    constructor() {
        super();
    }

    async findAll(): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                "/grupoParroquial",
            );
            return response;
        } catch (error: any) {
            console.log("Error al obtener todos los grupos parroquiales");
            return [];
        }
    }

    async editGrupo(id: number, data: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.put(
                "/grupoParroquial/edit/" + id,
                data,
            );
            return response;
        } catch (error) {
            throw new Error("Error al editar el grupo parroquial");
        }
    }

    async createGrupo(data: any, nameId: number): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                `/grupoParroquial/create/${nameId}`,
                data,
            );
            return response;
        } catch (error) {
            console.log('error', error);
            throw new Error("Error al crear el grupo parroquial");
        }
    }

    async deleteGrupo(id: number): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.delete(
                "/grupoParroquial/delete/" + id,
            );
            return response;
        } catch (error) {
            throw new Error("Error al eliminar el grupo parroquial");
        }
    }

    async getGrupoWithOnlyIdAndName(actividadParroquialNombreId: number): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.get(
                `/grupoParroquial/allGroupsWithIdAndName?actividadParroquialNombreId=${actividadParroquialNombreId}`,
            );
            return response;
        } catch (error) {
            console.log("Error al obtener el grupo parroquial");
        }
    }

}

const grupoParroquialService = new GrupoParroquialService();

export default grupoParroquialService;
