import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { stylesActividadParroquial } from "./CreateActividadParroquialStyles";
import { ActividadParroquialRoutes, ActividadParroquialTextos, CreateActividadParroquialEnum, ValuesEnum, ValuesLabelEnum } from "../../../enums/pages/CreateActividadParroquialEnum";
import { FormTextSend, Sizes } from "../../../enums/components/componentsEnum";
import React from "react";
import { addNotification } from "../../../utils/notifications";
import { queryClient } from "../../../App";
import { ActividadParroquialState, initializeActividadParroquial } from "../../../utils/pages/ActividadParroquialUtils";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import actividadParroquialService from "../../../services/actividadParroquial/ActividadParroquialService";
import grupoParroquialService from "../../../services/grupoParroquial/GrupoParroquialService";
import peopleService from "../../../services/people";
import { LoadState } from "../../../enums/LoadStateEnum";
import { ActividadParroquialNombre, Group, Liturgia } from "../InterfacesActividadParroquial";
import FormCoordinator from "./FormCoordinator";
import { Scope } from "../../../enums/ScopeEnum";
import NavLinkAdministratorGroups from "./LinksAdministrationGroup";
import { sortByProperty } from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";
import turnoExpecificationService from "../../../services/turnosExpecification";

const CreateActividadParroquial = () => {
    const navigate = useNavigate();
    const [actividad, setActividad] = useState<ActividadParroquialState>({ ...initializeActividadParroquial() });
    const location = useLocation();
    const actividadId = location.state?.actividadId;
    const [groups, setGroups] = useState<Group[]>([]);
    const [actividadParroquialNombres, setActividadParroquialNombre] = useState<ActividadParroquialNombre[]>([]);
    const [liturgias, setLiturgias] = useState<Liturgia[]>([]);
    const [newGroup, setNewGroup] = useState("");
    const [newCategoria, setNewCategoria] = useState("");
    const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
    const [isAddingNewCategoria, setIsAddingNewCategoria] = useState(false);
    const loadState = useRef(LoadState.DEFAULT);
    const [isFetchingPerson, setIsFetchingPerson] = useState<boolean[]>([false]);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [activityIdFetched, setActivityIdFetched] = useState<number>(0);
    const [activityId, setActivityId] = useState<number>(0);
    const [encuentro, setEncuentro] = useState<any>();

    useEffect(() => {
        actividadParroquialService.getActividadNombres().then((response: any) => {
            const sortedResponse = sortByProperty(response, 'name');
            setActividadParroquialNombre(sortedResponse);
        }).catch((error: any) => {
            console.error(error);
        });

        actividadParroquialService.getLiturgiasWithOnlyIdAndType().then((response: any) => {
            setLiturgias(response);
        }).catch((error: any) => {
            console.error(error);
        });
    }, []);

    const handleItemChange = async (event: any, type: "groupId" | "nameId" | "liturgiaId") => {
        const value = event.target.value;

        // Update the state with the new value
        setActividad(prevState => ({
            ...prevState,
            [type]: value
        }));

        if (type === "groupId") {
            setActividad(prevState => ({
                ...prevState,
                scope: null,
                coordinators: [{
                    id: null,
                    name: "",
                    surname: "",
                    documentType: "",
                    document: "",
                    birthdate: "",
                    email: "",
                    phone: "",
                    rolesIglesia: [],
                }],
                task: "",
                liturgiaId: 0,
            }));
            setIsFetchingPerson((prevState) => {
                const updatedFetchingPerson = prevState.map(() => false);
                return updatedFetchingPerson;
            });
            setIsSubmitSuccessful(false);

            try {
                const response = await actividadParroquialService.findActividadByActivityNameIdAndGroupId(actividad.nameId, value);
                turnoExpecificationService.getEncuentroByActivityId(response.id).then((response: any) => {
                  setEncuentro(response[0]);
                }
              ).catch((error: any) => {
                console.error('Error al traer turnos', error);
              });
                if (response && Object.keys(response).length > 0) {
                    handleUploadActivityParroquial(CreateActividadParroquialEnum.ACTIVIDAD_PARROQUIAL_CARGADA_COORECTAMENTE);
                    setActivityIdFetched(response.id);

                    const coordinators = response.users && response.users.length > 0
                        ? response.users.map((coordinator: any, index: number) => {
                            // Upload coordinator data and set isFetchingPerson to true in correct index
                            setIsFetchingPerson((prevState) => {
                                const updatedFetchingPerson = [...prevState];
                                updatedFetchingPerson[index] = true;
                                return updatedFetchingPerson;
                            });

                            return {
                                id: coordinator.id || null,
                                name: coordinator.name || '',
                                surname: coordinator.surname || '',
                                documentType: coordinator.documentType || '',
                                document: coordinator.document || '',
                                birthdate: coordinator.birthdate || '',
                                email: coordinator.email || '',
                                phone: coordinator.phone || '',
                                task: coordinator.task || '',
                                responseToRiteId: coordinator.responseToRiteId || '',
                            };
                        })
                        : [{
                            id: null,
                            name: '',
                            surname: '',
                            documentType: '',
                            document: '',
                            birthdate: '',
                            email: '',
                            phone: '',
                            task: '',
                            responseToRiteId: '',
                        }];

                    setActividad(prevState => ({
                        ...prevState,
                        scope: response.scope,
                        task: response.task,
                        liturgiaId: response.liturgiaId,
                        coordinators: coordinators
                    }));
                } else {
                    console.log('No activity found');
                }
            } catch (error) {
                console.error(error);
            }
        } else if (type === "nameId") {
            await grupoParroquialService.getGrupoWithOnlyIdAndName(value).then((response: any) => {
                const sortedResponse = sortByProperty(response, 'name');
                setGroups(sortedResponse);
                setActividad(prevState => ({
                    ...prevState,
                    scope: null,
                    coordinators: [{
                        id: null,
                        name: "",
                        surname: "",
                        documentType: "",
                        document: "",
                        birthdate: "",
                        email: "",
                        phone: "",
                        rolesIglesia: [],
                    }],
                    task: "",
                    liturgiaId: 0,
                }));
                setIsFetchingPerson((prevState) => {
                    const updatedFetchingPerson = prevState.map(() => false);
                    return updatedFetchingPerson;
                });
                setIsSubmitSuccessful(false);

            }).catch((error: any) => {
                console.error(error);
            });
        }
    };

    const handleAddNewGroup = (nameId: number) => {
        if (newGroup.trim() === '') return;
        const newId = groups.length + 1;
        const newElement: Group = { id: newId, name: newGroup };
        setGroups([...groups, newElement]);
        setActividad({ ...actividad, groupId: newId });
        setNewGroup("");
        setIsAddingNewGroup(false);
        setActividad(prevState => ({
            ...prevState,
            scope: null,
            coordinators: [{
                id: null,
                name: "",
                surname: "",
                documentType: "",
                document: "",
                birthdate: "",
                email: "",
                phone: "",
                rolesIglesia: [],
            }],
            task: "",
            liturgiaId: 0,
        }));
        setIsFetchingPerson((prevState) => {
            const updatedFetchingPerson = prevState.map(() => false);
            return updatedFetchingPerson;
        });
        setIsSubmitSuccessful(false);
        grupoParroquialService.createGrupo(newElement, nameId);
    };

    const handleAddNewActividadParroquialNombre = () => {
        if (newCategoria.trim() === '') return;

        const newId = actividadParroquialNombres.length + 1;
        const newElement: ActividadParroquialNombre = { id: newId, name: newCategoria };
        setActividadParroquialNombre([...actividadParroquialNombres, newElement]);
        setActividad({ ...actividad, nameId: newId });
        setNewCategoria("");
        setIsAddingNewCategoria(false);
        setActividad(prevState => ({
            ...prevState,
            scope: null,
            coordinators: [{
                groupId: null,
                id: null,
                name: "",
                surname: "",
                documentType: "",
                document: "",
                birthdate: "",
                email: "",
                phone: "",
                rolesIglesia: [],
            }],
            task: "",
            liturgiaId: 0,
        }));
        setIsFetchingPerson((prevState) => {
            const updatedFetchingPerson = prevState.map(() => false);
            return updatedFetchingPerson;
        });
        setIsSubmitSuccessful(false);
        actividadParroquialService.createActividadOnlyWithName(newElement);
    };

    const handleCancelAddGroup = () => {
        setIsAddingNewGroup(false);
        setNewGroup("");
    };

    const handleCancelAddCategoria = () => {
        setIsAddingNewCategoria(false);
        setNewCategoria("");
    };

    const handleScopeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const scopeValue = value as Scope;
        setActividad({ ...actividad, scope: scopeValue });
    };

    const resetStateValidator = (
        state: LoadState = LoadState.DEFAULT,
        isFetch = false,
    ) => {
        loadState.current = state;
    };

    const handleFetchPerson = async (dni: string, documentType: string, index: number) => {
        dni = dni ?? "";
        documentType = documentType ?? "";
        let isValid = false;
        if (dni && documentType) {
            try {
                const response: any = await peopleService.findPeopleForActivityParroquial(dni, documentType);
                if (response) {
                    setIsFetchingPerson((prevState) => {
                        const updatedFetchingPerson = [...prevState];
                        updatedFetchingPerson[index] = true;
                        return updatedFetchingPerson;
                    });
                    const {
                        birthdate,
                        name,
                        surname,
                        document_type: documentType,
                        document,
                        email,
                        phone,
                        idUser: id,
                        rolesIglesia,
                    } = response;

                    setActividad((prevState) => {
                        const updatedCoordinators = [...prevState.coordinators];
                        updatedCoordinators[index] = {
                            ...updatedCoordinators[index],
                            birthdate: birthdate ? formatDate(birthdate) : updatedCoordinators[index].birthdate,
                            id,
                            name,
                            surname,
                            documentType,
                            document,
                            email,
                            phone,
                            rolesIglesia,
                        };

                        return {
                            ...prevState,
                            coordinators: updatedCoordinators,
                        };
                    });
                    isValid = true;
                }
            } catch (error) {
                console.error("Error fetching person:", error);
            }
        }
        resetStateValidator(isValid ? LoadState.LOADED : undefined, isValid);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDocumentTypeChange = (e: any, value: any) => {
        setActividad(prevState => ({
            ...prevState,
            coordinators: prevState.coordinators.map(coordinator => ({
                ...coordinator,
                document_type: value.id
            }))
        }));
    };

    const handleAgendarTurnos = () => {
        const actividadParroquialNombre = actividadParroquialNombres.find(categoria => categoria.id === actividad.nameId);
        const grupoParroquial = groups.find(group => group.id === actividad.groupId);

        navigate(`/panel/actividad-parroquial/create-event`, {
            state: {
                actividadParroquialNombre,
                grupoParroquial,
                coordinators: actividad.coordinators,
                activityId: activityIdFetched !== 0 ? activityIdFetched : activityId,
                encuentro,
            }
        });
    };

    const handleUploadActivityParroquial = (message: string) => {
        addNotification(queryClient, "success", message);
        setIsSubmitSuccessful(true);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const handleSuccess = (message: string) => {
            addNotification(queryClient, "success", message);
            setIsSubmitSuccessful(true);
        };

        const handleError = (message: string) => {
            addNotification(queryClient, "error", message);
            setIsSubmitSuccessful(false);
        };

        if (isSubmitSuccessful) {
            actividadParroquialService.editActividad(actividad, activityIdFetched)
                .then((response) => {
                    setActividad((prevState) => ({
                        ...prevState,
                        coordinators: prevState.coordinators.map((coordinator, index) => {
                            const updatedUser = response.users[index];
                            return {
                                ...coordinator,
                                id: updatedUser.id,
                            };
                        })
                        })
                    )
                    handleSuccess(CreateActividadParroquialEnum.ACTIVIDAD_PARROQUIAL_ACTUALIZADA)
                  }
                    
                    )
                .catch((error: any) => handleError(CreateActividadParroquialEnum.ERROR_ACTUALIZAR_ACTIVIDAD_PARROQUIAL));
        } else {
            actividadParroquialService.createActividad(actividad)
              .then((response) => {
                setActivityId(response.id);
                setActividad((prevState) => ({
                    ...prevState,
                    coordinators: prevState.coordinators.map((coordinator, index) => {
                        const updatedUser = response.users[index];
                        return {
                            ...coordinator,
                            id: updatedUser.id,
                        };
                    })
                    })
                )
                handleSuccess(CreateActividadParroquialEnum.ACTIVIDAD_PARROQUIAL_CREADA)
              }
                
                )
                .catch((error: any) => handleError(CreateActividadParroquialEnum.ERROR_CREAR_ACTIVIDAD_PARROQUIAL));
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={stylesActividadParroquial.box1}
        >
            <Box
                sx={stylesActividadParroquial.box2}
            >
                <Box sx={stylesActividadParroquial.box3}>
                    <IconButton onClick={handleGoBack} sx={{}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={stylesActividadParroquial.typography}>
                        {CreateActividadParroquialEnum.CATEDRAL_DE_LA_PLATA}
                    </Typography>
                </Box>

                <Box sx={stylesActividadParroquial.containerLinks}>
                    <Typography variant="subtitle1" sx={stylesActividadParroquial.title}>
                        {ActividadParroquialTextos.TituloAdministracionGrupo}
                    </Typography>

                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.AltaActividad}
                        label={ActividadParroquialTextos.AltaActividad}
                        isActiveStyle={stylesActividadParroquial.activeLink}
                        defaultStyle={stylesActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        label={ActividadParroquialTextos.ProgramarEncuentro}
                        isActiveStyle={stylesActividadParroquial.activeLink}
                        defaultStyle={stylesActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.ListadoEncuentros}
                        label={ActividadParroquialTextos.ListadoEncuentros}
                        isActiveStyle={stylesActividadParroquial.activeLink}
                        defaultStyle={stylesActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.ListadoIntegrantes}
                        label={ActividadParroquialTextos.ListadoIntegrantes}
                        isActiveStyle={stylesActividadParroquial.activeLink}
                        defaultStyle={stylesActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.AltaParticipantes}
                        label={ActividadParroquialTextos.AltaParticipantes}
                        isActiveStyle={stylesActividadParroquial.activeLink}
                        defaultStyle={stylesActividadParroquial.italicLink}
                    />
                </Box>
                <div></div>
            </Box>
            <div style={stylesActividadParroquial.divForm}>
                <form onSubmit={handleSubmit}>
                    <Grid >
                        <Typography style={stylesActividadParroquial.typographyTipo}>
                            {CreateActividadParroquialEnum.SELECCIONE_LA_ACTIVIDAD_PARROQUIAL}
                        </Typography>
                        <Grid container alignItems="center">
                            <Grid item xs={11.5}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId={ValuesLabelEnum.NAME_ID}
                                        id={ValuesEnum.NAME_ID}
                                        value={actividad.nameId}
                                        onChange={(e) => handleItemChange(e, "nameId")}
                                        fullWidth
                                        size={Sizes.SMALL}
                                        style={stylesActividadParroquial.selectTipo}
                                        disabled={!!actividadId}
                                        required
                                    >
                                        {actividadParroquialNombres.map((categoria) => (
                                            <MenuItem key={categoria.id} value={categoria.id}>
                                                {categoria.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={0.5}>
                                <IconButton onClick={() => setIsAddingNewCategoria(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        {isAddingNewCategoria && (
                            <Grid container style={stylesActividadParroquial.addingNewCategory}>
                                <Grid item style={{ flexGrow: 1 }}>
                                    <TextField
                                        label={CreateActividadParroquialEnum.AGREGAR_NUEVA_CATEGORIA}
                                        value={newCategoria}
                                        onChange={(e) => setNewCategoria(e.target.value)}
                                        fullWidth
                                        size={Sizes.SMALL}
                                    />
                                </Grid>
                                <Grid item style={stylesActividadParroquial.GridAddNewCategory}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddNewActividadParroquialNombre}
                                        style={stylesActividadParroquial.buttonAddNewCategory}
                                    >
                                        {CreateActividadParroquialEnum.AGREGAR}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleCancelAddCategoria}>
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}

                        <Typography style={stylesActividadParroquial.typographyGroup}>
                            {CreateActividadParroquialEnum.SELECCIONE_GRUPO}
                        </Typography>
                        <Grid container>
                            <Grid item xs={11.5}>
                                <FormControl fullWidth>
                                    <Select
                                        value={actividad.groupId}
                                        onChange={(e) => handleItemChange(e, "groupId")}
                                        displayEmpty
                                        sx={stylesActividadParroquial.formSelect}
                                        fullWidth
                                        size={Sizes.SMALL}
                                        required
                                    >
                                        {groups?.map((group) => (
                                            <MenuItem key={group.id} value={group.id}>
                                                {group.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {actividad.nameId !== 0 && (

                                <Grid item xs={0.5}>
                                    <IconButton onClick={() => setIsAddingNewGroup(true)}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>

                        {isAddingNewGroup && (
                            <Grid container direction="column" style={stylesActividadParroquial.addingNewGroup}>

                                <Typography style={stylesActividadParroquial.typography}>
                                    {CreateActividadParroquialEnum.GRUPO_SE_AGREGARA_A_CATEGORIA_SELECCIONADA}
                                </Typography>

                                <Grid container direction="row" style={stylesActividadParroquial.addingNewGroup}>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <TextField
                                            label={CreateActividadParroquialEnum.AGREGAR_NUEVO_GRUPO}
                                            value={newGroup}
                                            onChange={(e) => setNewGroup(e.target.value)}
                                            fullWidth
                                            size={Sizes.SMALL}
                                        />
                                    </Grid>
                                    <Grid item style={stylesActividadParroquial.gridAddNewGroup}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e: any) => handleAddNewGroup(actividad.nameId)}
                                            style={stylesActividadParroquial.buttonAddNewGroup}
                                        >
                                            {CreateActividadParroquialEnum.AGREGAR}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleCancelAddGroup}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid sx={stylesActividadParroquial.gridFormControl}>
                        <Typography style={stylesActividadParroquial.typography}>
                            {CreateActividadParroquialEnum.ALCANCE_DE_LA_ACTIVIDAD}
                        </Typography>
                        <FormControl sx={stylesActividadParroquial.formControlRadios}>
                            <RadioGroup
                                aria-labelledby={CreateActividadParroquialEnum.ALCANCE_DE_LA_ACTIVIDAD}
                                name="scope"
                                value={actividad.scope}
                                onChange={handleScopeChange}
                                sx={stylesActividadParroquial.formRadioGroup}
                            >
                                <FormControlLabel value={Scope.PARROQUIAL} sx={stylesActividadParroquial.formRadioGroupItem} control={<Radio />} label={CreateActividadParroquialEnum.PARROQUIAL} />
                                <FormControlLabel value={Scope.DECANATO} sx={stylesActividadParroquial.formRadioGroupItem} control={<Radio />} label={CreateActividadParroquialEnum.DECANATO} />
                                <FormControlLabel value={Scope.ARQUIDIOCESANO} sx={stylesActividadParroquial.formRadioGroupItem} control={<Radio />} label={CreateActividadParroquialEnum.ARQUIDIOCESANO} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <FormCoordinator
                        actividad={actividad}
                        setActividad={setActividad}
                        handleDocumentTypeChange={handleDocumentTypeChange}
                        handleFetchPerson={handleFetchPerson}
                        handleItemChange={handleItemChange}
                        liturgias={liturgias}
                        actividadId={actividadId}
                        stylesActividadParroquial={stylesActividadParroquial}
                        isFetchingPerson={isFetchingPerson}
                    />

                    <Grid sx={stylesActividadParroquial.gridButton}>
                        <Button
                            type={FormTextSend.SUBMIT}
                            variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                            color={CreateActividadParroquialEnum.PRIMARY}
                            style={stylesActividadParroquial.buttonSave}
                        >
                            {CreateActividadParroquialEnum.GUARDAR}
                        </Button>
                        <Button
                            variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                            color={CreateActividadParroquialEnum.PRIMARY}
                            style={stylesActividadParroquial.buttonAgendarTurnos}
                            disabled={!isSubmitSuccessful}
                            onClick={handleAgendarTurnos}
                            sx={{
                                '&:hover::after': {
                                    content: '"Debes guardar primero"',
                                    position: "absolute",
                                    top: "-10.5em",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    backgroundColor: "#333",
                                    color: "#fff",
                                    padding: "0.5em",
                                    borderRadius: "0.25em",
                                    whiteSpace: "nowrap",
                                    zIndex: 10000,
                                },
                            }}
                        >
                            {encuentro ? CreateActividadParroquialEnum.EDITAR_TURNOS : CreateActividadParroquialEnum.AGENDAR_TURNOS}
                        </Button>
                    </Grid>
                </form>
            </div>
        </Box>
    );
};

export default CreateActividadParroquial;
