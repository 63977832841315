import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { stylesListadoActividadParroquial } from '../ListadoActividadesParroquiales/ListadoActividadesParroquialesStyles';
import NavLinkAdministratorGroups from '../createActividadParroquial/LinksAdministrationGroup';
import { ActividadParroquialRoutes, ActividadParroquialTextos, AltaParticipanteTextos } from '../../../enums/pages/CreateActividadParroquialEnum';
import { PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import { stylesActividadParroquial } from '../createActividadParroquial/CreateActividadParroquialStyles';
import { HeaderPropsForActivityParroquial } from '../../../utils/actividadParroquialUtils/UtilsActividadParroquial';

const LinksAndHeader: React.FC<HeaderPropsForActivityParroquial> = ({ handleGoBack }) => (
  <Box sx={stylesListadoActividadParroquial.box2}>
    <Box sx={stylesListadoActividadParroquial.box3}>
      <IconButton onClick={handleGoBack} sx={stylesListadoActividadParroquial.iconButton}>
        <ArrowBackIcon />
      </IconButton>
      <Typography style={PeopleActividadParroquialStyles.typography}>
        {AltaParticipanteTextos.CATEDRAL_DE_LA_PLATA}
      </Typography>
    </Box>

    <Box sx={stylesListadoActividadParroquial.containerLinks}>
      <Typography variant="subtitle1" sx={stylesActividadParroquial.title}>
        {ActividadParroquialTextos.TituloAdministracionGrupo}
      </Typography>

      <NavLinkAdministratorGroups
        to={ActividadParroquialRoutes.AltaActividad}
        label={ActividadParroquialTextos.AltaActividad}
        isActiveStyle={stylesListadoActividadParroquial.activeLink}
        defaultStyle={stylesListadoActividadParroquial.italicLink}
      />
      <NavLinkAdministratorGroups
        label={ActividadParroquialTextos.ProgramarEncuentro}
        isActiveStyle={stylesListadoActividadParroquial.activeLink}
        defaultStyle={stylesListadoActividadParroquial.italicLink}
      />
      <NavLinkAdministratorGroups
        to={ActividadParroquialRoutes.ListadoEncuentros}
        label={ActividadParroquialTextos.ListadoEncuentros}
        isActiveStyle={stylesListadoActividadParroquial.activeLink}
        defaultStyle={stylesListadoActividadParroquial.italicLink}
      />
      <NavLinkAdministratorGroups
        to={ActividadParroquialRoutes.ListadoIntegrantes}
        label={ActividadParroquialTextos.ListadoIntegrantes}
        isActiveStyle={stylesListadoActividadParroquial.activeLink}
        defaultStyle={stylesListadoActividadParroquial.italicLink}
      />
      <NavLinkAdministratorGroups
        to={ActividadParroquialRoutes.AltaParticipantes}
        label={ActividadParroquialTextos.AltaParticipantes}
        isActiveStyle={stylesListadoActividadParroquial.activeLink}
        defaultStyle={stylesListadoActividadParroquial.italicLink}
      />
    </Box>
  </Box>
);

export default LinksAndHeader;